import { createRouter, createWebHashHistory } from 'vue-router';
import Home from '../views/Home.vue';
import AsgCompany from '../views/Company.vue';
import AsgAnalysis from '../views/Analysis.vue';
import AsgAdvantage from '../views/Advantage.vue';
import AsgAlliance from '../views/Alliance.vue';
import AsgSupply from '../views/Supply.vue';
import AsgServices from '../views/Services.vue';
import AsgGameView from '../views/GameView.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/company',
    name: 'AsgCompany',
    component: AsgCompany
  },
  {
    path: '/Analysis',
    name: 'AsgAnalysis',
    component: AsgAnalysis
  },
  {
    path: '/Advantage',
    name: 'AsgAdvantage',
    component: AsgAdvantage
  }
  ,
  {
    path: '/Alliance',
    name: 'AsgAlliance',
    component: AsgAlliance
  },
  {
    path: '/Supply',
    name: 'AsgSupply',
    component: AsgSupply
  },
  {
    path: '/Services',
    name: 'AsgServices',
    component: AsgServices
  },
  {
    path: '/GameView',
    name: 'AsgGameView',
    component: AsgGameView
  },
];

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  }
});

export default router;

<template>
  <div class="content-section common">
    <Carousel :items="carouselItems" :indexs="currentImageIndex" />
    <div class="container fadeIn my-5">
      <div class="icons-row text-center">
        <div
          v-for="(item, index) in items"
          :key="item.title"
          @click="selectItem(index)"
          class="icon-title-wrapper"
          :class="{ active: selected === index }"
        >
          <div class="icon">{{ item.icon }}</div>
          <asg-ths v-show="selected === index" :texts="[item.title]" class="title" />
          <div v-show="selected !== index" class="title">{{ item.title }}</div>
        </div>
      </div>
      <transition name="fade" mode="out-in">
        <div class="content" :key="selected" id="content-focus">
          <div class="text-bubble">
            <img
              v-lazy="items[selected].image"
              alt=""
              @click="expandImage(items[selected].image)"
              class="clickable-image card"
            />
            <p style="margin-top: 20px">{{ items[selected].description }}</p>
            <!-- 添加的图片展示部分 -->
            <div v-if="items[selected].extraImages" class="extra-images">
              <img
                v-for="(extraImage, idx) in items[selected].extraImages"
                :key="idx"
                v-lazy="extraImage"
                alt=""
                @click="expandImage(extraImage)"
                class="clickable-image card"
              />
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div
      class="image-expand-overlay"
      v-if="expandedImage"
      @click="expandedImage = ''"
    >
      <img v-lazy="expandedImage" alt="图片" class="expanded-image" />
    </div>
  </div>
</template>

<script>
import Carousel from "@/components/Carousel.vue";
import AsgThs from "@/components/tks.vue";  // 引入 asg-ths 组件
export default {
  name: "AsgAdvantage",
  components: {
    Carousel,
    AsgThs,  // 注册组件
  },
  data() {
    return {
      carouselItems: [
        { src: require("@/assets/img/home/C4.jpg") },
        { src: require("@/assets/img/bg/bg123.jpg") },
        { src: require("@/assets/img/bg/bg77.jpg") },
        { src: require("@/assets/img/bg/bg2.jpg") },
        { src: require("@/assets/img/bg/bg124.jpg") },
        { src: require("@/assets/img/bg/bg125.jpg") },
      ],
      currentImageIndex: 0,
      selected: 0,
      expandedImage: "",
      items: [
        {
          icon: "📊",
          title: "商业逻辑",
          description:
            "按照消费送积分、积分产数字资产、数字资产享增值的商业逻辑，优势：重塑了一条从生产端到消费端全流程的商业分配体系。",
          image: require("@/assets/img/bg/szzc.png"),
        },
        {
          icon: "🏬",
          title: "线下实体-千城万店",
          description:
            "爱尚购拥有自己的连锁数字生活馆及联盟商家，目前在武汉和咸宁开设两家，未来将在全国布局，每个城市都将开一家数字生活馆，其他商家也可入驻爱尚购数字商城本地生活馆，服务本地消费会员。",
          image: require("@/assets/img/home/C4.jpg"),
        },
        {
          icon: "🛒",
          title: "线上商城 - 爱尚超市 - 爱尚瑧选",
          description:
            "爱尚购瑧选（深圳）供应链有限公司是线上爱尚超市履约方。购物的便捷性：线上下单一键代发！不改变消费习惯，支持7天无理由退货；全品类供应：家居厨具、户外运动、生活家居、精品饰品、服装配饰、科技数码、国际美妆等。",
          image: require("@/assets/img/bg/shop.jpg"),
        },
        {
          icon: "🪙",
          title: "数字资产ACC",
          description:
            "ACC数字资产是以Web3.0+DAO的去中心化理念，由爱上购科技（深圳）有限公司，引进全球顶尖技术研发团队成立的ACC实验室，历经24个月，基于币安链研发的消费生态链。ACC数字资产是致力于打造全球消费生态，助力经济互融，以创新消费为主题，以区块链技术为核心，践行消费惠民，以通过“公平的分配机制，兼顾所有的商业生态参与者，从而实现共同富裕”构建消费生态，用创新科技赋能实体带动产业升级，实现消费即股东的可持续发展。",
          image: require("@/assets/img/bg/acc.jpg"),
          extraImages: [
          
            require("@/assets/img/gyl/xzc.png"),
          ],
        },
      ],
    };
  },
  mounted() {
    this.startImageCarousel();
  },
  methods: {
    selectItem(index) {
      this.selected = index;
      this.$nextTick(() => {
        document.getElementById("content-focus").scrollIntoView({
          behavior: "smooth",
        });
      });
    },
    startImageCarousel() {
      // 设置定时器，每隔一定时间切换背景图片
      setInterval(() => {
        this.currentImageIndex =
          (this.currentImageIndex + 1) % this.carouselItems.length;
      }, 5000);
    },
    expandImage(image) {
      this.expandedImage = image;
    },
  },
};
</script>

<style scoped>
@import "@/assets/styles/common.css";
</style>

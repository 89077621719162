<template>
  <canvas ref="wavesCanvas"></canvas>
</template>

<script>
export default {
  name: "GradientAnimation",
  data() {
    return {
      circlesNum: 15,
      minRadius: 400,
      maxRadius: 400,
      speed: 0.005,
      circles: [],
    };
  },
  mounted() {
    this.setCanvasSize();
    this.createCircles();
    window.addEventListener("resize", this.handleResize);
    this.drawAnimation();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.setCanvasSize();
      this.createCircles();
    },
    setCanvasSize() {
      const canvas = this.$refs.wavesCanvas;
      const ctx = canvas.getContext("2d");
      canvas.width = window.innerWidth * devicePixelRatio;
      canvas.height = window.innerHeight * devicePixelRatio;
      ctx.scale(devicePixelRatio, devicePixelRatio);
      this.ctx = ctx;
      this.w = canvas.width;
      this.h = canvas.height;
    },
    createCircles() {
      this.circles = [];
      for (let i = 0; i < this.circlesNum; ++i) {
        this.circles.push(this.createCircle());
      }
    },
    createCircle() {
      return {
        x: Math.random() * this.w,
        y: Math.random() * this.h,
        angle: Math.random() * Math.PI * 2,
        radius: Math.random() * (this.maxRadius - this.minRadius) + this.minRadius,
        firstColor: `hsla(${10 + Math.random() * 20}, 100%, 70%, 1)`,
        secondColor: `hsla(${10 + Math.random() * 20}, 100%, 70%, 0)`,
      };
    },
    drawCircles() {
      this.circles.forEach((circle) => this.drawCircle(circle));
    },
    drawCircle(circle) {
      circle.angle += this.speed;
      const x = circle.x + Math.cos(circle.angle) * 200;
      const y = circle.y + Math.sin(circle.angle) * 200;
      const gradient = this.ctx.createRadialGradient(x, y, 0, x, y, circle.radius);
      gradient.addColorStop(0, circle.firstColor);
      gradient.addColorStop(1, circle.secondColor);

      this.ctx.globalCompositeOperation = "overlay";
      this.ctx.fillStyle = gradient;
      this.ctx.beginPath();
      this.ctx.arc(x, y, circle.radius, 0, Math.PI * 2);
      this.ctx.fill();
    },
    clearCanvas() {
      this.ctx.clearRect(0, 0, this.w, this.h);
    },
    drawAnimation() {
      this.clearCanvas();
      this.drawCircles();
      window.requestAnimationFrame(this.drawAnimation);
    },
  },
};
</script>

<style scoped>

canvas {
  position:absolute;
  top:0;
  left:0;
  height:100%;
  z-index:-1;

	background-image: 
	radial-gradient(in oklch circle at 0% 0%, #ffd100, transparent 50%),
	radial-gradient(in oklch circle at 80% 50%, #7ba7bc, transparent),
	radial-gradient(in oklch circle at 0% 100%, #ff6a13, transparent);
}
</style>
<!--   background-color: #34657f;
	background-image: 
	radial-gradient(in oklch circle at 0% 0%, #ffd100, transparent 50%),
	radial-gradient(in oklch circle at 80% 50%, #7ba7bc, transparent),
	radial-gradient(in oklch circle at 0% 100%, #ff6a13, transparent);
 -->

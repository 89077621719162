<template>
  <div class="box">
    <Navbar />
    <router-view />
    <GradientAnimation></GradientAnimation>
    <Nature></Nature>
    <runGame></runGame>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import Nature from "@/components/Nature.vue";
import GradientAnimation from "@/components/GradientAnimation.vue";
import runGame from "@/components/runGame.vue";
export default {
  name: "App",
  components: {
    Navbar,
    Footer,
    Nature,
    GradientAnimation,
    runGame
  },
  mounted() {
   
  },
  
  methods: {
   
  }
};
</script>


<style>
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-size: 16px;
  color: #000;
  
  
    
}
@media (max-width: 1200px) {
  html {
    font-size: 16px; /* 中等屏幕尺寸下的字体大小 */
  }
}

@media (max-width: 768px) {
  html {
    font-size: 14px; /* 小屏幕设备下的字体大小 */
  }
}
.box {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden; /* 防止超出内容 */
}


#app {
  font-family: serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: @text-color;
  margin-top: 80px;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
p {
  font-size: 1rem; /* 相对于根字体大小的 1 倍 */
}
</style>
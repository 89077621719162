<template>
  <canvas id="web3-background"></canvas>
</template>

<script>
export default {
  mounted() {
    this.initCanvas();
    window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
    cancelAnimationFrame(this.animationId); // 停止动画
  },
  methods: {
    initCanvas() {
      const canvas = document.getElementById("web3-background");
      const context = canvas.getContext("2d");

      let nodes = [];
      const totalNodes = window.innerWidth <= 768 ? 20 : 40; // 根据设备调整节点数量
      const nodeRadius = 5;
      const maxDistance = 100; // 调整连接的最大距离

      // 设置画布尺寸
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;

      // 随机生成颜色
      function getRandomColor() {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
      }

      // 随机生成节点
      class Node {
        constructor(x, y) {
          this.x = x || Math.random() * canvas.width;
          this.y = y || Math.random() * canvas.height;
          this.dx = (Math.random() - 0.5) * 2;
          this.dy = (Math.random() - 0.5) * 2;
          this.color = getRandomColor(); // 随机颜色
        }

        draw() {
          context.beginPath();
          context.arc(this.x, this.y, nodeRadius, 0, Math.PI * 2);
          context.fillStyle = this.color;
          context.fill();
        }

        move() {
          this.x += this.dx;
          this.y += this.dy;

          // 边界检测，反弹
          if (this.x <= 0 || this.x >= canvas.width) this.dx = -this.dx;
          if (this.y <= 0 || this.y >= canvas.height) this.dy = -this.dy;
        }
      }

      // 初始化节点
      function initNodes() {
        for (let i = 0; i < totalNodes; i++) {
          nodes.push(new Node());
        }
      }

      // 连接节点之间的线条
      function connectNodes() {
        for (let i = 0; i < nodes.length; i++) {
          for (let j = i + 1; j < nodes.length; j++) {
            const dx = nodes[i].x - nodes[j].x;
            const dy = nodes[i].y - nodes[j].y;
            const distance = Math.sqrt(dx * dx + dy * dy);

            if (distance < maxDistance) {
              context.beginPath();
              context.moveTo(nodes[i].x, nodes[i].y);
              context.lineTo(nodes[j].x, nodes[j].y);
              context.strokeStyle = `rgba(255, 255, 255, ${1 - distance / maxDistance})`;
              context.stroke();
            }
          }
        }
      }

      // 动画循环
      const animate = () => {
        context.clearRect(0, 0, canvas.width, canvas.height);
        nodes.forEach((node) => {
          node.move();
          node.draw();
        });
        connectNodes();

        this.animationId = requestAnimationFrame(animate);
      };

      // 启动动画
      initNodes();
      animate();
    },
    handleResize() {
      const canvas = document.getElementById("web3-background");
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    }
  }
};
</script>

<style scoped>
#web3-background {
  will-change: transform, opacity;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  background-image: 
    radial-gradient(in oklch circle at 0% 0%, #ffd100, transparent 50%),
    radial-gradient(in oklch circle at 80% 50%, #7ba7bc, transparent),
    radial-gradient(in oklch circle at 0% 100%, #ff6a13, transparent);
}
</style>

<template>
  <div class="video-container">
    <div
      class="card video-wrapper"
      v-for="(video, index) in videos"
      :key="index"
    >
      <h2 class="video-title">{{ video.title }}</h2>
      <div
        class="video-overlay"
        v-if="!video.loading && !video.playing"
        @click="playVideo(index)"
      >
        <i class="fas fa-play-circle play-icon"></i>
      </div>
      <!-- <video
        ref="videos"
        :id="`video${index}`"
        controls
        loop
        muted
        class="rounded-video"
        @loadeddata="handleVideoLoaded(index)"
        @play="pauseOtherVideos(index)"
        @pause="handlePause(index)"
        :poster="video.poster"
      >
        <source :src="video.src" type="video/mp4">
        您的浏览器不支持视频标签。
      </video> -->
      <video
        ref="videos"
        :id="`video${index}`"
        controls
        loop
        class="rounded-video"
        @loadeddata="handleVideoLoaded(index)"
        @play="pauseOtherVideos(index)"
        @pause="handlePause(index)"
        :poster="video.poster"
      >
        <source :src="video.src" type="video/mp4" />
        您的浏览器不支持视频标签。
      </video>
    </div>
  </div>
</template>

<script>
export default {
  name: "VideoDisplay",
  data() {
    return {
      videos: [
        {
          title: "企业介绍",
          src: require("@/assets/video/video3.mp4"),
          poster: require("@/assets/img/videoBg/qyjs.jpg"),
          playing: false,
          loading: true,
        },
        {
          title: "ACC介绍",
          src: require("@/assets/video/video2.mp4"),
          poster: require("@/assets/img/videoBg/acc.jpg"),
          playing: false,
          loading: true,
        },
        {
          title: "奥运冠军健儿寄语",
          src: require("@/assets/video/video1.mp4"),
          poster: require("@/assets/img/videoBg/jy.jpg"),
          playing: false,
          loading: true,
        },
        {
          title: "爱尚购集锦",
          src: require("@/assets/video/video4.mp4"),
          poster: require("@/assets/img/videoBg/jj.jpg"),
          playing: false,
          loading: true,
        },
        {
          title: "2024爱尚购青海之旅",
          src: require("@/assets/video/video7.mp4"),
          poster: require("@/assets/img/videoBg/qh.jpg"),
          playing: false,
          loading: true,
        },
        {
          title: "2023爱尚购新消费新征程云南之旅",
          src: require("@/assets/video/video5.mp4"),
          poster: require("@/assets/img/videoBg/2023.jpg"),
          playing: false,
          loading: true,
        },
        {
          title: "2022爱尚购年会",
          src: require("@/assets/video/video6.mp4"),
          poster: require("@/assets/img/videoBg/nh.jpg"),
          playing: false,
          loading: true,
        },
      ],
    };
  },
  methods: {
    playVideo(index) {
      const videoElement = this.$refs.videos[index];
      if (videoElement.readyState >= 2) {
        videoElement.play();
        this.videos[index].playing = true;
      }
    },
    pauseOtherVideos(currentIndex) {
      this.videos.forEach((video, i) => {
        if (i !== currentIndex && video.playing) {
          const videoElement = this.$refs.videos[i];
          if (!videoElement.paused) {
            videoElement.pause();
            this.videos[i].playing = false;
          }
        }
      });
    },
    handlePause(index) {
      this.videos[index].playing = false;
    },
    handleVideoLoaded(index) {
      this.videos[index].loading = false;
    },
    handleScroll() {
      this.videos.forEach((video, i) => {
        const videoElement = this.$refs.videos[i];
        if (!videoElement.paused) {
          videoElement.pause();
          this.videos[i].playing = false;
        }
      });
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
.video-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 40px 20px; /* 增加内边距 */
}

.video-wrapper {
  width: 80vw; /* 宽度占据视口宽度的80% */
  max-width: 1000px; /* 最大宽度 */
  height: auto; /* 高度自适应内容 */
  background-color: #fff; /* 背景颜色 */
  border-radius: 24px; /* 较大的圆角 */
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* 更柔和的阴影 */
  position: relative;
  display: flex;
  flex-direction: column; /* 使标题和视频垂直排列 */
  transition: transform 0.3s, box-shadow 0.3s; /* 添加过渡效果 */
}

.video-wrapper:hover {
  transform: translateY(-10px); /* 鼠标悬停时向上移动 */
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15); /* 鼠标悬停时的阴影 */
}

.video-title {
  font-size: 1.5rem;
  margin: 20px; /* 标题的上下和左右边距 */
  color: #333; /* 标题颜色 */
  font-weight: 600; /* 字体加粗 */
  text-align: left; /* 标题居左 */
  align-self: flex-start; /* 标题与容器左对齐 */
}

.rounded-video {
  width: 100%;
  height: auto; /* 视频高度自适应内容 */
  object-fit: cover; /* 确保视频填充容器 */
  display: block;
}


.video-overlay:hover {
  background-color: rgba(0, 0, 0, 0.6); /* 鼠标悬停时的覆盖颜色 */
}

.play-icon {
  font-size: 3rem;
  color: white;
}

.card {
  border: none; /* 移除卡片的边框 */
  background-color: rgba(255, 255, 255, 0.3); 
}

@media (max-width: 767.98px) {
  .video-wrapper {
    width: 100%; /* 在较小屏幕上，视频占满整行 */
    border-radius: 20px; /* 较小的圆角 */
  }

  .video-title {
    font-size: 1.2rem; /* 较小屏幕上的标题字体大小 */
    margin: 10px; /* 较小屏幕上的标题边距 */
  }

  .video-overlay {
    top: 40px; /* 较小屏幕上避开标题 */
    height: calc(100% - 40px); /* 较小屏幕上覆盖整个视频区域 */
  }
}
</style>

<template>
  <div class="container">
    <!-- Group 1 Carousel -->
    <div class="carousel slide card" data-bs-ride="carousel">
      <div class="carousel-inner">
        <div
          v-for="(item, index) in group1Items"
          :key="'group1_' + index"
          :class="['carousel-item', { active: index === activeIndex1 }]"
        >
          <img
            v-lazy="item.image"
            class="d-block w-100"
            alt=""
            @click="zoomImage(item.image)"
          />
        </div>
        <LoadingSpinner v-if="group1Items.length === 0" />
      </div>
      <button class="carousel-control-prev" type="button" @click="prevSlide(1)">
        <span aria-hidden="true">❮</span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" @click="nextSlide(1)">
        <span aria-hidden="true">❯</span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>

    <!-- Group 2 Carousel -->
    <div class="carousel slide card" data-bs-ride="carousel">
      <div class="carousel-inner">
        <div
          v-for="(item, index) in group2Items"
          :key="'group2_' + index"
          :class="['carousel-item', { active: index === activeIndex2 }]"
        >
          <img
            v-lazy="item.image"
            class="d-block w-100"
            alt=""
            @click="zoomImage(item.image)"
          />
        </div>
        <LoadingSpinner v-if="group2Items.length === 0" />
      </div>
      <button class="carousel-control-prev" type="button" @click="prevSlide(2)">
        <span aria-hidden="true">❮</span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" @click="nextSlide(2)">
        <span aria-hidden="true">❯</span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>

    <!-- Group 3 Carousel -->
    <div class="carousel slide card" data-bs-ride="carousel">
      <div class="carousel-inner">
        <div
          v-for="(item, index) in group3Items"
          :key="'group3_' + index"
          :class="['carousel-item', { active: index === activeIndex3 }]"
        >
          <img
            v-lazy="item.image"
            class="d-block w-100"
            alt=""
            @click="zoomImage(item.image)"
          />
        </div>
        <LoadingSpinner v-if="group3Items.length === 0" />
      </div>
      <button class="carousel-control-prev" type="button" @click="prevSlide(3)">
        <span aria-hidden="true">❮</span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" @click="nextSlide(3)">
        <span aria-hidden="true">❯</span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>

    <!-- Modal -->
    <div class="custom-modal" v-if="showModal" @click.self="closeModal">
      <div class="modal-content">
        <span class="close-btn" @click="closeModal">&times;</span>
        <img
          v-lazy="selectedImage"
          class="modal-image"
          :alt="selectedImageAlt"
          @click.stop
        />
      </div>
    </div>
    <!-- 显示加载中的状态
    <LoadingSpinner v-if="isLoading" /> -->
  </div>
</template>

<script>
import LoadingSpinner from "@/components/LoadingSpinner.vue";
export default {
  name: "QualificationDisplay",
  components: {
    LoadingSpinner,
  },
  data() {
    return {
      group1Items: [],
      group2Items: [],
      group3Items: [],
      activeIndex1: 0,
      activeIndex2: 0,
      activeIndex3: 0,
      selectedImage: "", // 用于保存当前选择的图片路径
      selectedImageAlt: "", // 图片的 alt 属性
      showModal: false, // 控制模态框显示隐藏
      isLoading: true, // 加载状态
    };
  },
  mounted() {
    this.group1Items = this.generateGroup1Items();
    this.group2Items = this.generateGroup2Items();
    this.group3Items = this.generateGroup3Items();
  },
  methods: {
    generateGroup1Items() {
      const images = [
        "z1.jpg",
        "z5.jpg",
        "z6.jpg",
        "z12.jpg",
        "z2.jpg",
        "z3.jpg",
        "z4.jpg",
        "z7.jpg",
        "z8.jpg",
        "z9.jpg",
        "z10.jpg",
        "z11.jpg",
        "z13.jpg",
        "z14.jpg",
        "z15.jpg",
        "z16.jpg",
      ];
      return this.generateItems(images);
    },
    generateGroup2Items() {
      const images = [
        "2.jpg",
        "4.jpg",
        "6.jpg",
        "8.jpg",
        "11.jpg",
        "12.jpg",
        "14.jpg",
        "16.jpg",
        "18.jpg",
        "20.jpg",
      ];
      return this.generateItems(images);
    },
    generateGroup3Items() {
      const images = [
        "1.jpg",
        "3.jpg",
        "5.jpg",
        "7.jpg",
        "9.jpg",
        "13.jpg",
        "15.jpg",
        "17.jpg",
        "19.jpg",
      ];
      return this.generateItems(images);
    },
    generateItems(images) {
      return images.map((img, index) => ({
        image: require(`@/assets/img/hxys/${img}`),
        description: `描述${index + 1}`,
      }));
    },
    nextSlide(groupIndex) {
      switch (groupIndex) {
        case 1:
          this.activeIndex1 = (this.activeIndex1 + 1) % this.group1Items.length;
          break;
        case 2:
          this.activeIndex2 = (this.activeIndex2 + 1) % this.group2Items.length;
          break;
        case 3:
          this.activeIndex3 = (this.activeIndex3 + 1) % this.group3Items.length;
          break;
        default:
          break;
      }
    },
    prevSlide(groupIndex) {
      switch (groupIndex) {
        case 1:
          this.activeIndex1 =
            (this.activeIndex1 - 1 + this.group1Items.length) %
            this.group1Items.length;
          break;
        case 2:
          this.activeIndex2 =
            (this.activeIndex2 - 1 + this.group2Items.length) %
            this.group2Items.length;
          break;
        case 3:
          this.activeIndex3 =
            (this.activeIndex3 - 1 + this.group3Items.length) %
            this.group3Items.length;
          break;
        default:
          break;
      }
    },
    zoomImage(imageSrc) {
      // 设置选定的图片和 alt 属性
      this.selectedImage = imageSrc;
      this.selectedImageAlt = "查看原图";
      // 显示模态框
      this.showModal = true;
    },
    closeModal() {
      // 关闭模态框
      this.showModal = false;
    },
  },
};
</script>

<style scoped>
.container {
  padding: 0 16px;
}

.carousel {
  margin-bottom: 20px; /* 添加轮播图之间的底部间距 */
}

.custom-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
}

.modal-content {
  position: relative;
  padding: 20px;
  max-width: 80%;
  max-height: 80%;
  overflow: auto;
  border-radius: 4px;
}

.close-btn {
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 1.5rem;
  cursor: pointer;
  color: #ffa500;
}

.modal-image {
  width: 100%;
  height: auto;
}

.carousel-control-prev span,
.carousel-control-next span {
  /* 使用更明显的渐变色作为箭头颜色 */
  color: #ffa500 !important; /* Fallback color */
  color: linear-gradient(
    135deg,
    #ffcc00,
    #ff6600
  ) !important; /* Gradient color */
  font-size: 30px; /* 调整箭头大小 */

  animation: breathe 1s infinite alternate; /* 添加呼吸动画 */
}

@keyframes breathe {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}
.card {
  border: none;
  transition: transform 0.2s;
  position: relative;
}

.card:hover {
  transform: scale(1.05);
}
</style>

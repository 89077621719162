<template>
  <div class="video-container">
    <!-- 选择框 -->
    <div class="form-floating">
     <TapAnimation />
      <select class="form-select video-title-option" v-model="selectedVideoIndex" @change="onVideoChange" aria-label="Floating label select example">
        <option v-for="(video, index) in videos" :key="index" :value="index">
          {{ video.title }}
        </option>
      </select>
      <!-- <label class="lab" for="floatingSelect">选择视频</label> -->
    </div>
    <!-- 当前选择的视频内容 -->
    <div v-if="selectedVideo" class="card video-wrapper">
      <div v-if="selectedVideo.accAddress" class="copy-link" @click="copyAccAddress(selectedVideo.accAddress)">
        <h5 style="color: #ffa500">
          点我复制{{ selectedVideo.accAddress.description }}
        </h5>
      </div>
      <h2 class="video-title">{{ selectedVideo.title }}</h2>
      <video
        ref="videos"
        :key="selectedVideoIndex"
        controls
        loop
        class="rounded-video"
        @loadeddata="handleVideoLoaded"
        @play="pauseOtherVideos"
        @pause="handlePause"
        :poster="selectedVideo.poster"
      >
        <source :src="selectedVideo.src" type="video/mp4" />
        您的浏览器不支持视频标签。
      </video>
    </div>
    
    <!-- 弹出提示 -->
    <div v-if="showPopup" class="popup">{{ popupMessage }}</div>
  </div>
</template>

<script>
import TapAnimation from "@/components/TapAnimation.vue";
export default {
  name: "StudyVideo",
  components: {
    TapAnimation,
  },
  data() {
    return {
      selectedVideoIndex: 0,
      videos: [
        {
          title: "如何下载HM钱包并创建币安链钱包地址",
          src: require("@/assets/video/1.mp4"),
          poster: require("@/assets/img/videoBg/1.png"),
          accAddress: null
        },
        {
          title: "新人注册方式一：用币安链钱包地址注册爱尚购商城账号。适用于：想创建多个钱包地址的用户",
          src: require("@/assets/video/2.mp4"),
          poster: require("@/assets/img/videoBg/2.png"),
          accAddress: null
        },
        {
          title: "新人注册方式二：在浏览器用手机号注册爱尚购商城账号。适用于：怕麻烦不想下载钱包的用户",
          src: require("@/assets/video/3.mp4"),
          poster: require("@/assets/img/videoBg/3.png"),
          accAddress: {
            address: "https://web2.aishanggou.cn/h5",
            description: "web2商城链接",
          },
        },
        {
          title: "商家如何入驻爱尚购商城",
          src: require("@/assets/video/4.mp4"),
          poster: require("@/assets/img/videoBg/4.png"),
          accAddress: {
            address: "https://dapp.aishangmall.com.cn/h5/?utm_source",
            description: "爱尚购新商城链接🔗（此链接为web3链接）",
          },
        },
        {
          title: "普通会员如何升级为VIP",
          src: require("@/assets/video/5.mp4"),
          poster: require("@/assets/img/videoBg/5.png"),
          accAddress: null,
        },
        {
          title: "商家核销流程",
          src: require("@/assets/video/6.mp4"),
          poster: require("@/assets/img/videoBg/6.png"),
          accAddress: {
            address: "https://store.aishangmall.com.cn/",
            description: "商家登入后台地址",
          },
        },
        {
          title: "商家核销码核销流程",
          src: require("@/assets/video/7.mp4"),
          poster: require("@/assets/img/videoBg/7.png"),
          accAddress: null,
        },
        {
          title: "会员核销码核销流程",
          src: require("@/assets/video/8.mp4"),
          poster: require("@/assets/img/videoBg/8.png"),
          accAddress: null,
        },
        {
          title: "线下面对面支付流程",
          src: require("@/assets/video/9.mp4"),
          poster: require("@/assets/img/videoBg/9.png"),
          accAddress: null,
        },
        {
          title: "会员核销申请流程",
          src: require("@/assets/video/10.mp4"),
          poster: require("@/assets/img/videoBg/10.png"),
          accAddress: null
        },
        {
          title: "如何添加新的ACC合约地址",
          src: require("@/assets/video/11.mp4"),
          poster: require("@/assets/img/videoBg/11.png"),
          accAddress: {
            address: "0x95e5Dd765c7d1E17E5C42Ece836409E0a21DCf16",
            description: "ACC合约地址",
          },
        },
        {
          title: "如何提现和充值ACC",
          src: require("@/assets/video/12.mp4"),
          poster: require("@/assets/img/videoBg/12.png"),
          accAddress: null
        },
        {
          title: "如何交易ACC",
          src: require("@/assets/video/13.mp4"),
          poster: require("@/assets/img/videoBg/13.png"),
          accAddress: null
        },
      ],
      showPopup: false,
      popupMessage: "",
    };
  },
  computed: {
    selectedVideo() {
      return this.videos[this.selectedVideoIndex];
    }
  },
  methods: {
    onVideoChange() {
      // 当选择框的值改变时触发
      this.selectedVideoIndex = parseInt(this.selectedVideoIndex, 10);
    },
    playVideo(index) {
      const videoElement = this.$refs.videos[index];
      if (videoElement && videoElement.readyState >= 2) {
        videoElement.play();
        this.videos[index].playing = true;
      }
    },
    pauseOtherVideos() {
      this.videos.forEach((video, i) => {
        const videoElement = this.$refs.videos[i];
        if (videoElement && i !== this.selectedVideoIndex && video.playing) {
          if (!videoElement.paused) {
            videoElement.pause();
            this.videos[i].playing = false;
          }
        }
      });
    },
    handleScroll() {
      if (Array.isArray(this.$refs.videos)) {
        this.$refs.videos.forEach((videoElement, i) => {
          if (videoElement && !videoElement.paused) {
            videoElement.pause();
            this.videos[i].playing = false;
          }
        });
      }
    },
    copyAccAddress(accAddress) {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard
          .writeText(accAddress.address)
          .then(() => {
            this.popupMessage = ` 已复制${accAddress.description}，可直接粘贴`;
            this.showPopup = true;
            setTimeout(() => {
              this.showPopup = false;
            }, 2000); // 2秒后隐藏提示信息
          })
          .catch((err) => {
            console.error("无法复制地址: ", err);
          });
      } else {
        const textArea = document.createElement("textarea");
        textArea.value = accAddress.address;
        document.body.appendChild(textArea);
        textArea.select();
        try {
          document.execCommand("copy");
          this.popupMessage = ` 已复制${accAddress.description}，可直接粘贴`;
          this.showPopup = true;
          setTimeout(() => {
            this.showPopup = false;
          }, 2000); // 2秒后隐藏提示信息
        } catch (err) {
          console.error("无法复制地址: ", err);
        }
        document.body.removeChild(textArea);
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
  .video-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding: 40px 20px; /* 增加内边距 */
 
  }

  .form-floating {
  /*  width: 80vw;  宽度占据视口宽度的80% */
    max-width: 1000px; /* 最大宽度 */
    width: 100%;
    position:relative;
  }

  .video-wrapper {
    width: 80vw; /* 宽度占据视口宽度的80% */
    max-width: 1000px; /* 最大宽度 */
    height: auto; /* 高度自适应内容 */
    background-color: #fff; /* 背景颜色 */
    border-radius: 24px; /* 较大的圆角 */
    overflow: hidden;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* 更柔和的阴影 */
    position: relative;
    display: flex;
    flex-direction: column; /* 使标题和视频垂直排列 */
    transition: transform 0.3s, box-shadow 0.3s; /* 添加过渡效果 */
  }

  .video-wrapper:hover {
    transform: translateY(-10px); /* 鼠标悬停时向上移动 */
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15); /* 鼠标悬停时的阴影 */
  }

  .video-title {
    font-size: 1.5rem;
    margin: 20px; /* 标题的上下和左右边距 */
    color: #333; /* 标题颜色 */
    font-weight: 600; /* 字体加粗 */
    text-align: left; /* 标题居左 */
    align-self: flex-start; /* 标题与容器左对齐 */
  }

  .copy-link {
    font-size: 1rem;
    color: red !important;
    cursor: pointer;
    margin: 10px 20px; /* 与标题对齐 */
    text-decoration: underline;
    align-self: flex-start;
  }

  .rounded-video {
    width: 100%;
    height: auto; /* 视频高度自适应内容 */
    object-fit: cover; /* 确保视频填充容器 */
    display: block;
  }

  .popup {
    position: fixed;
    bottom: 50%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #28a745;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1rem;
    z-index: 1000;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* 更柔和的阴影 */
    animation: fadeInOut 2s ease-in-out;
  }

  @keyframes fadeInOut {
    0%,
    100% {
      opacity: 0;
    }
    10%,
    90% {
      opacity: 1;
    }
  }

  .play-icon {
    font-size: 3rem;
    color: white;
  }

  .card {
    border: none; /* 移除卡片的边框 */
  }

  @media (max-width: 767.98px) {
    .video-wrapper {
      width: 100%; /* 在较小屏幕上，视频占满整行 */
      border-radius: 20px; /* 较小的圆角 */
    }

    .video-title {
      font-size: 1.2rem; /* 较小屏幕上的标题字体大小 */
      margin: 10px; /* 较小屏幕上的标题边距 */
    }

    .copy-link {
      font-size: 0.9rem; /* 较小屏幕上的链接字体大小 */
      margin: 5px 10px; /* 较小屏幕上的链接边距 */
    }
  }
  .video-title-option {
  white-space: nowrap; /* 防止换行 */
  overflow: hidden; /* 隐藏溢出内容 */
  text-overflow: ellipsis; /* 使用省略号表示溢出的内容 */
  background-color: rgba(255, 255, 255, 0.3); 
}
.form-floating>.form-select{
  padding-top: 0; 
  padding-bottom: 0; 
}
</style>

import { createApp  } from 'vue'; // 导入 createApp 函数
import App from './App.vue'; // 导入根组件
import router from './router'; // 导入路由实例
import 'bootstrap/dist/css/bootstrap.min.css'; // 导入 Bootstrap 的 CSS 文件
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // 导入 Bootstrap 的 JS 文件
import VueLazyload from 'vue3-lazyload'; // 导入 VueLazyload 插件
import LoadingSpinner from './components/LoadingSpinner.vue'; // 导入自定义加载组件
const app = createApp(App); // 创建 Vue 应用实例
// import axios from './axios'; // 引入 axios 实例
// app.config.globalProperties.$axios = axios;
// 安装 VueLazyload 插件并配置选项
app.use(VueLazyload, {
  // 配置选项示例，根据实际需求配置
  loading: LoadingSpinner, // 使用 Bootstrap 加载图标
  error: '/src/assets/img/error/error.png', // 加载失败时显示的图片
  attempt: 3, // 加载尝试次数
});

app.use(router); // 使用路由实例

app.mount('#app'); // 挂载应用到指定元素

<template>
  <div class="background">
    <Carousel :items="carouselItems" :indexs="currentImageIndex" />
    <div class="container mt-5">
      <div class="text-center fadeIn">
        <div class="intro-text fadeInUp">
          <div class="boxs">
            <div class="wave -one"></div>
            <div class="wave -two"></div>
            <div class="wave -three"></div>
            <div class="title ">
              <p>概况</p>
              <h3 class="text-secondary card">GENERAL SITUATION</h3>
            </div>
          </div>

          <div class="content-section">
            <div
              class="paragraph-with-image text-bubble"
              v-for="(paragraph, index) in paragraphs"
              :key="index"
            >
              <img
                v-lazy="paragraph.imgSrc"
                alt=""
                class="inline-image card"
                @click="expandImage(paragraph.imgSrc)"
              />
              <p class="text-muted">
                {{ paragraph.text }}
                <a
                  :href="paragraph.link"
                  target="_blank"
                  class="more-info-link card-a"
                  >图文链接{{ paragraph.hint }}</a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="image-expand-overlay"
      v-if="expandedImage"
      @click="expandedImage = ''"
    >
      <img v-lazy="expandedImage" alt="" class="expanded-image" />
    </div>
  </div>
</template>

<script>
import Carousel from "@/components/Carousel.vue";
export default {
  name: "AsgCompany",
  components: {
    Carousel,
  },
  data() {
    return {
      carouselItems: [
        { src: require("@/assets/img/home/C4.jpg") },
        { src: require("@/assets/img/bg/bg123.jpg") },
        { src: require("@/assets/img/bg/bg77.jpg") },
        { src: require("@/assets/img/bg/bg2.jpg") },
        { src: require("@/assets/img/bg/bg124.jpg") },
        { src: require("@/assets/img/bg/bg125.jpg") },
      ],
      currentImageIndex: 0,
      paragraphs: [
        {
          text: "爱上购科技（深圳）有限公司成立于2019年4月，是一家创新驱动的科技研发企业。其经营范围涵盖电子商务，人工智能行业应用系统集成服务、经济信息咨询等。爱上购科技（深圳）有限公司，拥有知识产权方面共有商标信息 21 条，专利申请 2 个，软件著作权 13 个，此外还拥有资质证书 3 个，行政许可 9 个。",
          imgSrc: require("@/assets/img/bg/bg0.jpg"),
          link: "https://h5.jp.cjn.cn/#/h5/news?informationId=3646875&sourceType=0&type=android&isDetail=0",
          hint: "(爱尚购武汉数字生活馆盛大开业)",
        },
        {
          text: "公司创始团队引进全球顶尖技术人才，基于web3+DAO+DEFI等区块链底层技术，按照消费送积分、积分产数字资产、数字资产享增值的商业逻辑。 秉持 “消费创新之理念，共同富裕之目标” ，以线下实体商业为载体，以线上商城为桥梁，以价值互联网为基础网络，以区块链技术为底层架构，重塑了一条从生产端到消费端全流程的商业分配体系。",
          imgSrc: require("@/assets/img/bg/bg13.jpg"),
          hint: "(不忘初心砥砺前行凝心聚力再创辉煌2023爱尚购迎春联谊会圆满举办)",
          link: "https://mbd.baidu.com/newspage/data/landingshare?preview=1&pageType=1&isBdboxFrom=1&context=%7B%22nid%22%3A%22news_9754580618540374967%22%2C%22sourceFrom%22%3A%22bjh%22%7D&sShare=1",
        },
        {
          text: "爱尚购用区块链技术驾驭金融的力量，以金融的力量解决需求不足的社会问题，用创新科技赋能实体，带动产业升级。秉承共建、共享、利他、共赢的经营理念，无论你是商家还是消费者，爱上购去中心化生态系统都能让你成为受益者，实现资本反哺惠民。",
          imgSrc: require("@/assets/img/bg/bg15.jpg"),
          hint: "(2022汉交会湖北爱尚购数字商城 开创中国新消费 扩大内需新高地)",
          link: "https://mbd.baidu.com/newspage/data/landingshare?preview=1&pageType=1&isBdboxFrom=1&context=%7B%22nid%22%3A%22news_10029686704246745836%22%2C%22sourceFrom%22%3A%22bjh%22%7D&sShare=1",
        },
        {
          text: "企业愿景：成为中国最受信赖的消费平台；\n企业宗旨：敬天爱人、修己达人；\n企业价值观：共建、共享、利他、共赢；\n企业理念：消费即股东，天天享分红；",
          imgSrc: require("@/assets/img/home/C4.jpg"),
          hint: "(爱尚购年会暨ACC消费生态链上线启动仪式圆满举行)",
          link: "https://www.toutiao.com/article/7320480885705998859/?app=news_article&timestamp=1704433087&use_new_style=1&req_id=2024010513380659CB975C34F8E45416AE&group_id=7320480885705998859&share_token=9E96E72D-E71D-46B0-B4F7-1884DB9836C7&tt_from=weixin&utm_source=weixin&utm_medium=toutiao_ios&utm_campaign=client_share&wxshare_count=2&source=m_redirect",
        },
      ],
      expandedImage: null,
    };
  },
  mounted() {
    this.startImageCarousel();
  },
  methods: {
    startImageCarousel() {
      setInterval(() => {
        this.currentImageIndex =
          (this.currentImageIndex + 1) % this.carouselItems.length;
      }, 5000);
    },
    expandImage(imgSrc) {
      this.expandedImage = imgSrc;
    },
  },
};
</script>

<style scoped>
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeInUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
.container {
  z-index: 10;
  position: relative;
}
.fadeIn {
  animation: fadeIn 1s ease-out;
}
.intro-text {
  animation: fadeInUp 1s ease-out;
  animation-delay: 0.5s;
  animation-fill-mode: backwards;
}
.text-primary {
  background-color: rgba(255, 255, 255, 0);
  color: #007bff;
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 1rem;
}
.text-secondary {
  background-color: rgba(255, 255, 255, 0);
  color: #6c757d;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 1rem;
}
.text-bubble {
  background-color: rgba(255, 255, 255, 0.6);
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}
.text-muted {
  font-size: 1.2rem;
  font-weight:700;
  line-height: 2;
  text-align: left;
  color: #6c757d;
  white-space: pre-wrap; /* 保留文本中的换行符 */
}
.background {
  position: relative;
  overflow: hidden;
}
.carousel {
  position: relative;
  height: 400px;
}
.carousel-item img {
  width: 100%;
  height: 400px;
  object-fit: cover;
}
.card {
  border: none;
  transition: transform 0.2s;
  position: relative;
}
.card:hover {
  transform: scale(1.05);
}
.card-a {
  border: none;
  transition: transform 0.2s;
  display: inline-block;
}
.card-a:hover {
  transform: scale(1.05);
}
.paragraph-with-image {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: nowrap;
  cursor: pointer;
}
.inline-image {
  width: 200px;
  height: 250px;
  margin-right: 20px;
  flex-shrink: 0;
  margin-bottom: 10px;
  border-radius: 15px;
  object-fit: cover;
}
.text-muted {
  flex-grow: 1;
}
.image-expand-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  cursor: pointer;
}
.expanded-image {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
  border-radius: 15px;
}
@media (max-width: 768px) {
  .paragraph-with-image {
    flex-direction: column;

    flex-wrap: wrap;
  }
  .inline-image {
    width: 100%;
    max-width: 300px;
    margin-right: 0;
  }
  .text-muted {
    text-align: left;
  }
}

.boxs {
  height: 150px;
  border-radius: 5px;
  box-shadow: 0 2px 30px rgba(black, 0.2);
  background: lighten(#f0f4c3, 10%);
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  will-change: transform, opacity;
}

.wave {
  opacity: 0.4;
  background:#ffd100;
  height: 300px;
  margin-top: -266px;
  transform-origin: 50% 48%;
  border-radius: 40%;
  animation: drift 3000ms infinite linear;
}

.wave.-three {
  animation: drift 5000ms infinite linear;
}

.wave.-two {
  animation: drift 7000ms infinite linear;
  opacity: 0.1;
  background: yellow;
}

.boxs:after {
  content: "";
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(#e8a, 1), rgba(#def, 0) 80%, rgba(white, .5))!important;
  z-index: 11;
  transform: translate3d(0, 0, 0);
}

.title {
  p{
    font-size:3rem;
  }
  display:flex;
  flex-direction: column; /* 纵向排列 */
  align-items: center; /* 可选: 水平居中 */
  justify-content: center; /* 可选: 垂直居中 */
  font-family: 'Playfair Display', serif;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;

  letter-spacing: .4em;
  color: white;
  z-index: 1;

  transform: translate3d(0, 0, 0);
  text-transform: uppercase;
  text-shadow: 0 1px 0 rgba(black, 0.1);
  text-indent: .3em;
}
@keyframes drift {
  from {
    transform: rotate(0deg);
  }
  from {
    transform: rotate(360deg);
  }
}
</style>

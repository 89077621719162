<template>
  <div class="container mt-4">
    <div class="row row-cols-1">
      <div v-for="(item, index) in carouselItems" :key="index" class="col mb-4">
        <div class="card position-relative">
          <img v-lazy="item.image" class="card-img-top rounded-img" alt="..." @load="imageLoaded(index)" />
        </div>
      </div>
    </div>
    <div v-if="lastImageLoaded" class="text-center mt-2">
      <asgThs></asgThs>
    </div>
    <!-- 显示加载中的状态 -->
    <LoadingSpinner v-if="isLoading" />
  </div>
</template>

<script>
import { ref } from 'vue';
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import asgThs from "@/components/tks.vue";
export default {
  name: "AsgSupply",
  components: {
    LoadingSpinner,
    asgThs
  },
  setup() {
    const carouselItems = ref([
        { image: require("@/assets/img/gyl/top.png") },
        { image: require("@/assets/img/gyl/xyt.png") },
        { image: require("@/assets/img/gyl/qjh.png") },
        { image: require("@/assets/img/gyl/qpl.png") },
        { image: require("@/assets/img/gyl/ptys.png") },
        { image: require("@/assets/img/gyl/ptjz.png") },
        { image: require("@/assets/img/gyl/hzhb.png") },
        { image: require("@/assets/img/gyl/ysgh.png") },
        { image: require("@/assets/img/gyl/shjz.png") },
      ]);
    const lastImageLoaded = ref(false);
    const isLoading = ref(true);
    const totalImages = carouselItems.value.length;
    const loadedImages = ref(0);

    const imageLoaded = () => {
   
      loadedImages.value += 1;

      if (loadedImages.value === totalImages) {
        lastImageLoaded.value = true;
        isLoading.value = false;
  
      }
    };
    return {
      carouselItems,
      lastImageLoaded,
      isLoading,
      imageLoaded
      
    };
  }
};
</script>

<style scoped>
.card {
  border: none;
  transition: transform 0.2s;
  position: relative;
  border-radius: 20px;
}

.card:hover {
  transform: scale(1.05);
}

.rounded-img {
  border-radius: 20px;
}

.last-image-text {
  font-size: 14px;
  color: #7B2;
  margin: 0;
  padding: 5px;
  border-radius: 10px;
  display: inline-block;
  animation: fadeInUp 0.5s ease;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
:deep(.rugrats) {
  font-size: calc(2vw + 2vh);
}
</style>

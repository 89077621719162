<template>
  <div class="text-center loading-overlay">
    <div id="loader">
      <div id="emoji" :class="{ active: emojiActive }">{{ currentEmoji }}</div>
      <div id="progress">{{ progress }}%</div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';

export default {
  name: 'LoadingProgress',
  setup() {
    const progress = ref(0);
    const currentEmoji = ref('😴');
    const emojiActive = ref(false);
    const emojis = ["😴", "😪", "😕", "😐", "🙂", "😊", "😀", "😃", "😄", "😁", "🥳"];
    let lastUpdateTime = null;

    const updateProgress = (timestamp) => {
      if (!lastUpdateTime) {
        lastUpdateTime = timestamp;
      }

      const deltaTime = timestamp - lastUpdateTime;

      if (deltaTime >= 120) {
        progress.value = Math.min(progress.value + 1, 100);
        
        if (progress.value % 10 === 0 && progress.value <= 100) {
          const emojiIndex = progress.value / 10;
          currentEmoji.value = emojis[emojiIndex];
          emojiActive.value = true;

          setTimeout(() => {
            emojiActive.value = false;
          }, 500);
        }

        lastUpdateTime = timestamp;
      }

      if (progress.value < 100) {
        requestAnimationFrame(updateProgress);
      }
    };

    onMounted(() => {
      requestAnimationFrame(updateProgress);
    });

    return {
      progress,
      currentEmoji,
      emojiActive
    };
  }
};
</script>




<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:ital,wght@0,300;0,400;0,500;0,600;0,700&display=swap');

body {
  padding: 0;
  margin: 0;
  min-height: 100vh;
  display: grid;
  place-items: center;
  background: black;
  font-family: "Chakra Petch", sans-serif;
  overflow: hidden;
  color: white;
  letter-spacing: .4em;
}
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* 确保在最上层 */
}

.scene {
  position: relative;
  width: 300px;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

#emoji {
  font-size: 80px;
}

#progress {
  font-size: 30px;
}

#loader {
  --filter: drop-shadow(0px 0px 2px #fff) 
    drop-shadow(0px 0px 3px #00e5ff) blur(0px);
  --filter2: drop-shadow(0px 0px 25px #fff) 
    drop-shadow(0px 0px 30px #00e5ff) blur(6px);
  --t1: scale(1) skew(0deg);
  --t2: scale(2) skew(10deg);
  --t3: scale(2) skew(-10deg);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0px;
  filter: var(--filter);
}

.active {
  animation: scaleUp .09s ease-in-out;
}

@keyframes scaleUp {
  0% {
    transform: var(--t1);
    filter: var(--filter);
  }
  50% {
    transform: var(--t2);
    filter: var(--filter2);
  }
  60% {
    transform: var(--t3);
  }
  100% {
    transform: var(--t1);
    filter: var(--filter);
  }
}
</style>

<template>
  <div class="pl">
    <div class="pl__coin">
      <div class="pl__coin-flare"></div>
      <div class="pl__coin-flare"></div>
      <div class="pl__coin-flare"></div>
      <div class="pl__coin-flare"></div>
      <div class="pl__coin-layers">
        <div class="pl__coin-layer">
          <div class="pl__coin-inscription" style="height: 1.5em"></div>
          <p style="color: yellow">ACC</p>
        </div>
        <div class="pl__coin-layer"></div>
        <div class="pl__coin-layer"></div>
        <div class="pl__coin-layer"></div>
        <div class="pl__coin-layer">
          <div class="pl__coin-inscription"></div>
        </div>
      </div>
    </div>
    <div class="pl__shadow"></div>
  </div>
</template>
<script>
export default {
  name: "AsgAcc",
};
</script>

<style lang="scss">
@use "sass:map";

$timings: (
  "ease-in-out-sine": cubic-bezier(0.37, 0, 0.63, 1),
  "ease-in-sine": cubic-bezier(0.12, 0, 0.39, 0),
  "ease-out-sine": cubic-bezier(0.61, 1, 0.88, 1),
  "ease-in-out-cubic": cubic-bezier(0.65, 0, 0.35, 1),
  "ease-in-cubic": cubic-bezier(0.33, 0, 0.67, 0),
  "ease-out-cubic": cubic-bezier(0.33, 1, 0.67, 1),
);

* {
  border: 0;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
:root {
  --hue: 163;
  --hue2: 43;
  --bg: hsl(var(--hue), 90%, 30%);
  --fg: hsl(0, 0%, 100%);
}

.pl {
  $debug: false;
  $stopped: false;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: auto;

  @if $debug == true {
    &,
    &__coin {
      outline: 1px solid;
    }
  }
  &__coin,
  &__coin-flare,
  &__coin-inscription,
  &__coin-layers,
  &__shadow {
    animation: {
      duration: 2s;
      timing-function: map.get($timings, "ease-in-out-sine");
      iteration-count: infinite;
    }
    @if $stopped == true {
      animation: {
        delay: 0s;
        play-state: paused;
      }
    }
  }
  &__coin {
    animation-name: bounce;
    position: relative;
    transform: translateY(0.5em);
    z-index: 1;

    &-flare,
    &-inscription,
    &-layer {
      position: absolute;
    }
    &-flare {
      animation-name: flare1;
      background-color: hsl(0, 0%, 100%);
      clip-path: polygon(
        50% 0,
        67% 33%,
        100% 50%,
        67% 67%,
        50% 100%,
        33% 67%,
        0 50%,
        33% 33%
      );
      top: 0;
      left: -0.5em;
      width: 1em;
      height: 1em;
      transform: scale(0);
      z-index: 1;

      &:nth-child(2) {
        animation-name: flare2;
        top: -0.5em;
        left: 0;
      }
      &:nth-child(3) {
        animation-name: flare3;
        left: 3em;
      }
      &:nth-child(4) {
        animation-name: flare4;
        top: -0.5em;
        left: 3.5em;
      }
    }
    &-inscription {
      animation-name: inscription-front;
      background-color: hsl(var(--hue2), 90%, 45%);
      box-shadow: 0.1875em 0 0 hsl(var(--hue2), 90%, 40%) inset;
      top: 50%;
      left: 50%;
      width: 0.2em;
      height: 2.5em;
      transform: translate(-50%, -50%) rotateZ(30deg);
    }
    &-layer {
      background-color: hsl(var(--hue2), 90%, 50%);
      backface-visibility: hidden;
      border-radius: 50%;
      box-shadow: 0 0 0 0.375em hsl(var(--hue2), 90%, 70%) inset;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      &:first-child {
        transform: translate3d(0, 0, 0.5em);
      }
      &:nth-child(2) {
        transform: translate3d(0, 0, 0.49em) rotateY(180deg);
      }
      &:nth-child(3) {
        backface-visibility: visible;
        border-radius: 0;
        left: 50%;
        width: 0.98em; // ideally 1em but adjusted to prevent z-fighting
        transform: translateX(-50%) rotateY(90deg);
      }
      &:nth-child(4) {
        transform: translate3d(0, 0, -0.49em);
      }
      &:last-child {
        transform: translate3d(0, 0, -0.5em) rotateY(180deg);
      }
      &:nth-child(n + 2):nth-last-child(n + 2) {
        background-color: hsl(var(--hue2), 90%, 40%);
        box-shadow: none;
      }
    }
    &-layer:last-child &-inscription {
      animation-name: inscription-back;
      transform: translate(-50%, -50%) rotateZ(-30deg);
    }
    &-layers {
      animation-name: roll;
      position: relative;
      width: 4em;
      height: 4em;
      transform: rotateY(-15deg) rotateZ(-30deg);
      transform-style: preserve-3d;
    }
  }
  &__shadow {
    animation-name: shadow;
    background-color: hsla(var(--hue), 90%, 10%, 0.3);
    border-radius: 50%;
    width: 4em;
    height: 0.5em;
  }
}

/* Animations */
@keyframes bounce {
  from,
  50%,
  to {
    animation-timing-function: map.get($timings, "ease-in-cubic");
    transform: translateY(0.5em);
  }
  25%,
  75% {
    animation-timing-function: map.get($timings, "ease-out-cubic");
    transform: translateY(5.1em);
  }
}
@keyframes flare1 {
  from,
  75%,
  to {
    animation-timing-function: map.get($timings, "ease-in-cubic");
    transform: scale(0);
  }
  87.5% {
    animation-timing-function: map.get($timings, "ease-out-cubic");
    transform: scale(1);
  }
}
@keyframes flare2 {
  from,
  to {
    animation-timing-function: map.get($timings, "ease-out-cubic");
    transform: scale(1);
  }
  12.5%,
  87.5% {
    animation-timing-function: map.get($timings, "ease-in-cubic");
    transform: scale(0);
  }
}
@keyframes flare3 {
  from,
  25%,
  50%,
  to {
    animation-timing-function: map.get($timings, "ease-in-cubic");
    transform: scale(0);
  }
  37.5% {
    animation-timing-function: map.get($timings, "ease-out-cubic");
    transform: scale(1);
  }
}
@keyframes flare4 {
  from,
  37.5%,
  62.5%,
  to {
    animation-timing-function: map.get($timings, "ease-in-cubic");
    transform: scale(0);
  }
  50% {
    animation-timing-function: map.get($timings, "ease-out-cubic");
    transform: scale(1);
  }
}
@keyframes inscription-front {
  from,
  75% {
    animation-timing-function: map.get($timings, "ease-in-sine");
    box-shadow: 0.1875em 0 0 hsl(var(--hue2), 90%, 40%) inset;
  }
  87.49% {
    animation-timing-function: steps(1);
    box-shadow: 0.875em -0.75em 0 hsl(var(--hue2), 90%, 40%) inset;
  }
  87.5% {
    animation-timing-function: map.get($timings, "ease-out-sine");
    box-shadow: -0.875em 0.75em 0 hsl(var(--hue2), 90%, 40%) inset;
  }
  to {
    box-shadow: 0.1875em 0 hsl(var(--hue2), 90%, 40%) inset;
  }
}
@keyframes inscription-back {
  from,
  75% {
    box-shadow: -1em -0.875em 0 hsl(var(--hue2), 90%, 40%) inset;
  }
  to {
    box-shadow: 1.125em 1em 0 hsl(var(--hue2), 90%, 40%) inset;
  }
}
@keyframes roll {
  from,
  75% {
    transform: rotateY(-15deg) rotateZ(-30deg) rotateY(0);
  }
  to {
    transform: rotateY(-15deg) rotateZ(-30deg) rotateY(-1turn);
  }
}
@keyframes shadow {
  from,
  50%,
  to {
    animation-timing-function: map.get($timings, "ease-in-cubic");
    background-color: hsla(var(--hue), 90%, 10%, 0.3);
    transform: scale(0.6);
  }
  25%,
  75% {
    animation-timing-function: map.get($timings, "ease-out-cubic");
    background-color: hsla(var(--hue), 90%, 10%, 0.5);
    transform: scale(1);
  }
}
.pl__shadow {
  position: absolute;
  top: 150px;
  z-index: 111;
	will-change: transform, opacity;
}
.pl__coin {
  will-change: transform, opacity;
}
</style>

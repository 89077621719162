<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" class="wrapper translate">
    <circle :class="circleClass" cx="89.43" cy="64.48" r="19.46" />
    <path class="hand-tap" d="M139.93,102.68,98.81,95.75V65.2A9.25,9.25,0,0,0,89.56,56h0a9.25,9.25,0,0,0-9.25,9.25v57.36L71,111.77c-3.61-3.61-8.44-3.89-13.08,0,0,0-7.24,5.84-3.83,9.25l34,34h42.63a9.25,9.25,0,0,0,9.07-7.43l6.82-34.09A9.28,9.28,0,0,0,139.93,102.68Z"/>
  </svg>
</template>

<script>
import { ref, onMounted } from "vue";

export default {
  name: "TapAnimation",
  setup() {
    const circleClass = ref("tap-none");

    onMounted(() => {
      setTimeout(() => {
        circleClass.value = "tap tap-anime";
        document.querySelector(".wrapper path").classList.add("hand-tap-anime");
      }, 5000);
    });

    return {
      circleClass,
    };
  },
};
</script>

<style scoped>
.wrapper {
  z-index: 1000;
}

/* BASE RULES */
svg {
  display: inline-block;
  width: 100px;
  transform: rotate(-550deg);
}

/* SVG RULES */
.hand-tap {
  fill: yellow;
  stroke: #000;
  stroke-width: 3px;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.tap {
  fill: transparent;
  stroke: #000;
  stroke-width: 3px;
  stroke-linecap: round;
  stroke-linejoin: round;
  opacity: 0.5;
}

.tap-none {
  fill: transparent;
  stroke: #000;
  stroke-width: 0px;
  stroke-linecap: round;
  stroke-linejoin: round;
  opacity: 0.5;
}

/* ANIMATION KEYFRAMES */
@keyframes tap {
  0% {
    transform: rotateX(0deg);
  }
  10% {
    transform: rotateX(12.5deg);
  }
  25% {
    transform: rotateX(25deg);
  }
}

@keyframes tap-circle {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  75% {
    transform: scale(1.05);
    opacity: 0.6;
  }
  80% {
    transform: scale(1);
    opacity: 0.5;
  }
}

@keyframes translate {
  0% {
    right: 24vw;
    top:-60px;
  }
  100% {
    right: 0;
    top: -60px;
  }
}

/* SVG ANIMATION */
.wrapper * {
  transform-origin: 50% 50%;
  perspective: 100px;
}

.hand-tap-anime {
  animation: tap 1.25s ease-out backwards;
  animation-iteration-count: infinite;
}

.tap-anime {
  animation: tap-circle 1.25s ease-out backwards;
  animation-iteration-count: infinite;
}

.translate {
  position: absolute;
  will-change: transform, opacity;
  right: 0px;
  top: -60px;
  animation: translate 5s ease-out backwards;
  animation-iteration-count: 1;
  pointer-events: none;
}
</style>

<template>
  <div>
    <div class="rugrats" v-for="(text, index) in texts" :key="index">
      {{ text }}
    </div>
  </div>
</template>

<script>
import { onMounted } from 'vue';
export default {
  name:'asgThs',
  props: {
    texts: {
      type: Array,
      default: () => ['感谢浏览'],
    },
  },
  setup() {
    onMounted(() => {
      const divs = document.querySelectorAll('.rugrats');

      divs.forEach(function (div) {
        const text = div.textContent;
        div.innerHTML = '';

        for (let i = 0; i < text.length; i++) {
          const span = document.createElement('span');
          span.textContent = text[i];
          div.appendChild(span);
        }

        let spans = div.querySelectorAll('span');

        function shuffle(array) {
          for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
          }
          return array;
        }

        spans = shuffle(Array.from(spans));

        function getRandomValue() {
          return Math.random() * 0.4 - 0.24;
        }

        function applyRandomTransform() {
          spans.forEach(function (span) {
            const xOffset = getRandomValue() * 10;
            const yOffset = getRandomValue() * 15;
            const rotation = getRandomValue() * 6;

            span.style.transform = `translate(${xOffset}px, ${yOffset}px) rotate(${rotation}deg)`;
            span.style.textIndent = `${xOffset}px`;
          });
        }

        function getRandomColor() {
          const letters = '0123456789ABCDEF';
          let color = '#';
          for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
          }
          return color;
        }

        let currentIndex = 0;

        function changeColorSequentially() {
          spans.forEach(function (span, index) {
            const colorIndex = (index + currentIndex) % spans.length;
            span.style.color =
              colorIndex === 0 ? getRandomColor() : spans[colorIndex - 1].style.color;
          });

          currentIndex = (currentIndex + 1) % spans.length;
        }

        setInterval(changeColorSequentially, 250);
        setInterval(applyRandomTransform, 100);
      });
    });

    return {
      
    };
  }
};
</script>

<style scoped>
body {
  margin: 12vh;
  text-align: center;
  font-size: calc(8vw + 8vh);
  background: #314d79;
  color: #fff;
  font-family: sans-serif;
  letter-spacing: -.3vw;
  overflow: hidden;
  font-family: "Lacquer", system-ui;
}

.rugrats {
  margin: 0 auto;
  text-align: center;
}

.rugrats span {
  display: inline-block;
  transition: color linear 0.5s forwards;
  -webkit-text-stroke-width: 0.32vw;
  -webkit-text-stroke-color: black;
  text-shadow: 0.4vw 0.5vw #000;
}

.rugrats span {
  text-transform: lowercase;
}
</style>

<template>
  <div class="container mt-4">
      <div class="row row-cols-1">
        <div v-for="(item, index) in carouselItems" :key="index" class="col mb-4">
          <div class="card position-relative">
            <img
              v-lazy="item.image"
              class="card-img-top rounded-img"
              alt=""
              @load="imageLoaded(index)"
            />
          </div>
        </div>
      </div>
      <div v-if="lastImageLoaded" >
        <asgThs :texts="customTexts"></asgThs>
      </div>
    <LoadingSpinner v-if="isLoading" />
  </div>
</template>

<script>
import { ref ,onBeforeUnmount } from 'vue';
import confetti from 'canvas-confetti';
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import asgThs from "@/components/tks.vue";
export default {
  name: "AsgServices",
  components: {
    LoadingSpinner,
    asgThs
  },
  setup() {
    const carouselItems = ref([
      { image: require("@/assets/img/hezuo/CJ2A9622.jpg") },
      { image: require("@/assets/img/hezuo/CJ2A9627.jpg") },
      { image: require("@/assets/img/hezuo/CJ2A9628.jpg") },
      { image: require("@/assets/img/hezuo/CJ2A9629.jpg") },
      { image: require("@/assets/img/hezuo/CJ2A9630.jpg") },
      { image: require("@/assets/img/hezuo/CJ2A9631.jpg") },
      { image: require("@/assets/img/hezuo/CJ2A9632.jpg") },
      { image: require("@/assets/img/hezuo/CJ2A9634.jpg") },
      { image: require("@/assets/img/hezuo/CJ2A9635.jpg") },
      { image: require("@/assets/img/hezuo/CJ2A9636.jpg") },
      { image: require("@/assets/img/hezuo/CJ2A9637.jpg") },
    ]);
    const lastImageLoaded = ref(false);
    const isLoading = ref(true);
    const totalImages = carouselItems.value.length;
    const loadedImages = ref(0);
     let confettiAnimation = null;
    const imageLoaded = () => {

      
      loadedImages.value += 1;

      if (loadedImages.value === totalImages) {
        lastImageLoaded.value = true;
        isLoading.value = false;
        showConfetti();
      }
    };

    const showConfetti = () => {
      const duration = 3 * 1000; // 持续时间3秒
      const end = Date.now() + duration;

      const frame = () => {
        confetti({
          particleCount: 3,
          angle: 60,
          spread: 55,
          origin: { x: 0 }
        });
        confetti({
          particleCount: 3,
          angle: 120,
          spread: 55,
          origin: { x: 1 }
        });

        if (Date.now() < end) {
          confettiAnimation = requestAnimationFrame(frame);
        }
      };

      frame();
    };

    const stopConfetti = () => {
      if (confettiAnimation) {
        cancelAnimationFrame(confettiAnimation);
      }
    };

    onBeforeUnmount(() => {
      stopConfetti();
    });
    const customTexts = ref(['感谢浏览']); 
    return {
      carouselItems,
      lastImageLoaded,
      isLoading,
      imageLoaded,
      customTexts
    };
  }
};
</script>

<style scoped>
.card {
  border: none;
  transition: transform 0.2s;
  position: relative;
  border-radius: 20px;
}

.card:hover {
  transform: scale(1.05);
}

.rounded-img {
  border-radius: 20px;
}

.last-image-text {
  font-size: 14px;
  color: #7B2;
  margin: 0;
  padding: 5px;
  border-radius: 10px;
  display: inline-block;
  animation: fadeInUp 0.5s ease;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
:deep(.rugrats){
  font-size: calc(2vw + 2vh);
}
</style>

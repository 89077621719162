<template>
  <footer class="bg-dark text-light">
    <div class="container" style="padding: 1rem">
      <div class="text-center">
        <p class="mb-0">&copy; 爱尚购臻选（深圳）供应链有限公司. 保留所有权利。</p>
        <!-- 备案信息 -->
        <p class="mb-0">
          备案号：
          <a href="https://beian.miit.gov.cn/" target="_blank" class="text-decoration-none text-light">
            粤ICP备2024242379号
          </a>
        </p>
        <p class="mb-0">网站域名：aishanggou.cn</p>
     
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'MiFooter',
}
</script>

<style scoped>
/* 自定义页脚样式 */
footer {
  flex-shrink: 0; /* 不让页脚在容器大小不够时收缩 */
  margin-top: auto; /* 将页脚推到容器的底部 */
  display: flex; /* 使用 Flexbox 布局 */
  width: 100%; /* 宽度占满容器 */
}

footer .container {
  width: 100%; /* 确保页脚内容宽度占满页脚 */
}

footer h5 {
  font-size: 1rem;
  font-weight: bold;
}

footer ul {
  padding-left: 0;
  list-style: none;
}

footer ul li {
  line-height: 2;
}

footer hr {
  border-color: rgba(255, 255, 255, 0.1);
}

footer a {
  color: #fff;
}

footer a:hover {
  color: #ffa500;
  text-decoration: none;
}
.text-center{
  font-size: 0.8rem;
}
@media (max-width: 991.98px) {
  footer .col-md-3 {
    flex-basis: 50%;
    max-width: 50%;
  }
}

@media (max-width: 767.98px) {
  footer .col-md-3 {
    flex-basis: 100%;
    max-width: 100%;
  }
}
</style>

<template>
  <div>
    <WhackAMoleGame />
  </div>
</template>

<script>
import WhackAMoleGame from "@/components/WhackAMoleGame.vue";

export default {
  components: {
    WhackAMoleGame,
  },
};
</script>

<template>
  <div class="section-center">
    <div class="section-up">
      <div class="sun">
        <span></span>
      </div>
      <div class="cloud-left">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="cloud-middle">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="cloud-right">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="bird-wrapper">
        <div class="left-wing">
          <span></span>
        </div>
        <div class="right-wing">
          <span></span>
        </div>
      </div>
      <div class="bird-wrapper snd">
        <div class="left-wing">
          <span></span>
        </div>
        <div class="right-wing">
          <span></span>
        </div>
      </div>
      <div class="bird-wrapper trd">
        <div class="left-wing">
          <span></span>
        </div>
        <div class="right-wing">
          <span></span>
        </div>
      </div>
      <div class="building-left">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="building-right">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span><span></span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="left-tree">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="right-tree">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="hill">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="hill snd">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="hill trd">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="hill frt">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <div class="section-down">
      <div class="ship-wrapper">
        <div class="ship">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div class="water-big-wrapper">
        <div class="water-big-wrap">
          <div class="water-big">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div class="water-big snd">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div class="water-big trd">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      <div class="water-lines-wrapper">
        <div class="water-lines">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div class="water-lines-wrapper right">
        <div class="water-lines">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div class="fish-wrapper">
        <div class="fish-wrap">
          <div class="fish">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      <div class="fish-wrapper right">
        <div class="fish-wrap">
          <div class="fish">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      <div class="water-wrapper top">
        <div class="water-wrap">
          <div class="water">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div class="water snd">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div class="water trd">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      <div class="water-wrapper">
        <div class="water-wrap">
          <div class="water">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div class="water snd">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div class="water trd">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      <div class="water-wrapper top right">
        <div class="water-wrap">
          <div class="water">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div class="water snd">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div class="water trd">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      <div class="water-wrapper right">
        <div class="water-wrap">
          <div class="water">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div class="water snd">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div class="water trd">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name:'AsgNature'
  }
</script>

<style scoped>
body {
  display: block;
  background-color: #ffbf00;
}
.section-center {
  will-change: transform, opacity;
  position: relative;
  width: 100%;
  height: 450px;
  display: block;
  z-index: 6;
  margin: 0 auto;
  overflow: hidden;
}
.section-up {
  position: absolute;
  width: 100%;
  height: 270px;
  left: 0;
  top: 0;
  display: block;
  z-index: 5;
}
.hill {
  position: absolute;
  width: 115px;
  height: 27px;
  left: 30px;
  bottom: 0;
  display: block;
  z-index: 15;
}
.hill.snd {
  left: 115px;
  z-index: 14;
}
.hill.trd {
  transform: rotateY(180deg);
  left: auto;
  right: 30px;
}
.hill.frt {
  transform: rotateY(180deg);
  left: auto;
  right: 110px;
  z-index: 14;
}
.hill span {
  position: absolute;
  width: 60px;
  height: 60px;
  background-color: saddlebrown;
  left: 4px;
  top: 0;
  display: block;
  border-radius: 50%;
  border: 2px solid #030308;
  border-color: #030308 transparent transparent transparent;
}
.hill span:nth-child(2) {
  width: 110px;
  height: 110px;
  left: -6px;
  top: -5px;
  background-color: transparent;
  transform: rotate(5deg);
  border-color: transparent transparent transparent #030308;
}
.hill span:nth-child(3) {
  width: 10px;
  height: 10px;
  left: 2px;
  top: 22px;
  transform: rotate(-55deg);
  border: none;
  border-radius: 0;
}
.hill span:nth-child(4) {
  width: 40px;
  height: 40px;
  left: auto;
  top: 11px;
  right: 5px;
  border-color: #030308 transparent transparent transparent;
}
.hill span:nth-child(5) {
  width: 110px;
  height: 110px;
  left: auto;
  top: 3px;
  right: -8px;
  background-color: transparent;
  transform: rotate(85deg);
  border-color: #030308 transparent transparent transparent;
}
.hill span:nth-child(6) {
  width: 24px;
  height: 24px;
  left: auto;
  top: -5px;
  right: 31px;
  background-color: transparent;
  border-color: transparent transparent #030308 transparent;
}
.hill span:nth-child(7) {
  width: 17px;
  height: 4px;
  left: auto;
  top: 10px;
  right: 49px;
  background-color: transparent;
  border-radius: 0;
  transform: rotate(33deg);
  border-color: #030308 transparent transparent transparent;
}
.hill span:nth-child(8) {
  width: 20px;
  height: 10px;
  left: 65px;
  top: 21px;
  transform: rotate(-35deg);
  border: none;
  border-radius: 0;
}
.hill span:nth-child(9) {
  width: 30px;
  height: 10px;
  left: 46px;
  top: 15px;
  transform: rotate(33deg);
  border: none;
  border-radius: 0;
}
.hill span:nth-child(10) {
  width: 20px;
  height: 10px;
  left: 60px;
  top: 19px;
  border: none;
  border-radius: 0;
}
.hill span:nth-child(11) {
  width: 20px;
  height: 10px;
  left: 101px;
  top: 29px;
  border: none;
  border-radius: 0;
  transform: rotate(45deg);
}
.left-tree {
  position: absolute;
  width: 20px;
  height: 33px;
  left: 33px;
  bottom: 8px;
  display: block;
  z-index: 10;
  transform: rotate(11deg) skewX(3deg);
  transform-origin: center bottom;
  animation: moveTree 1.6s ease infinite;
}
@keyframes moveTree {
  0%,
  100% {
    transform: rotate(11deg) skewX(3deg);
  }
  50% {
    transform: rotate(-11deg) skewX(-3deg);
  }
  100% {
    transform: rotate(11deg) skewX(3deg);
  }
}
.left-tree span {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 0;
  top: 0;
  border-radius: 50%;
  border: 2px solid #030308;
  background-color: lawngreen;
}
.left-tree span:nth-child(2) {
  width: 2px;
  height: 25px;
  left: 9px;
  top: auto;
  bottom: 0;
  border-radius: 0;
  border: none;
  background-color: #030308;
}
.left-tree span:nth-child(3) {
  width: 2px;
  height: 5px;
  left: 7px;
  top: auto;
  bottom: 20px;
  border-radius: 0;
  border: none;
  background-color: #030308;
  transform: rotate(-45deg);
}
.left-tree span:nth-child(4) {
  width: 2px;
  height: 5px;
  left: 11px;
  top: auto;
  bottom: 17px;
  border-radius: 0;
  border: none;
  background-color: #030308;
  transform: rotate(45deg);
}
.right-tree {
  position: absolute;
  width: 20px;
  height: 33px;
  right: 25px;
  bottom: 3px;
  display: block;
  z-index: 10;
  transform: rotate(11deg) skewX(3deg);
  transform-origin: center bottom;
  animation: moveTree 1.6s ease infinite;
}
.right-tree span {
  position: absolute;
  width: 18px;
  height: 18px;
  left: 1px;
  top: 2px;
  border-radius: 50%;
  border: 2px solid #030308;
  background-color: lawngreen;
}
.right-tree span:nth-child(2) {
  width: 2px;
  height: 25px;
  left: 9px;
  top: auto;
  bottom: 0;
  border-radius: 0;
  border: none;
  background-color: #030308;
}
.right-tree span:nth-child(3) {
  width: 2px;
  height: 5px;
  left: 7px;
  top: auto;
  bottom: 20px;
  border-radius: 0;
  border: none;
  background-color: #030308;
  transform: rotate(-45deg);
}
.right-tree span:nth-child(4) {
  width: 2px;
  height: 5px;
  left: 11px;
  top: auto;
  bottom: 17px;
  border-radius: 0;
  border: none;
  background-color: #030308;
  transform: rotate(45deg);
}
.right-tree span:nth-child(5) {
  position: absolute;
  width: 14px;
  height: 14px;
  left: 3px;
  top: -6px;
  border-radius: 50%;
  border: 2px solid #030308;
  background-color: lawngreen;
  border-bottom-color: transparent;
}
.building-left {
  position: absolute;
  left: 62px;
  bottom: 3px;
  display: block;
  z-index: 9;
}
.building-left span {
  position: absolute;
  left: 0;
  bottom: 0;
  border: 2px solid #030308;
  background-color: antiquewhite;
  width: 17px;
  height: 42px;
}
.building-left span:nth-child(2) {
  left: 20px;
  width: 30px;
  height: 64px;
  background: cadetblue;
}
.building-left span:nth-child(3) {
  left: 23px;
  bottom: 62px;
  width: 24px;
  height: 9px;
}
.building-left span:nth-child(4) {
  left: 54px;
  width: 20px;
  height: 46px;
  background: darkgrey;
}
.building-left span:nth-child(5) {
  left: 56px;
  bottom: 44px;
  width: 16px;
  height: 7px;
}
.building-left span:nth-child(6) {
  left: 2px;
  bottom: 27px;
  border: none;
  background-color: #030308;
  width: 6px;
  height: 2px;
}
.building-left span:nth-child(7) {
  left: 22px;
  bottom: 44px;
  border: none;
  background-color: #030308;
  width: 11px;
  height: 2px;
}
.building-left span:nth-child(8) {
  left: 39px;
  bottom: 20px;
  border: none;
  background-color: #030308;
  width: 11px;
  height: 2px;
}
.building-left span:nth-child(9) {
  left: 56px;
  bottom: 31px;
  border: none;
  background-color: #030308;
  width: 8px;
  height: 2px;
}
.building-left span:nth-child(10) {
  left: 29px;
  bottom: 71px;
  border: none;
  background-color: #030308;
  width: 2px;
  height: 17px;
}
.building-left span:nth-child(11) {
  left: 39px;
  bottom: 71px;
  border: none;
  background-color: #030308;
  width: 2px;
  height: 17px;
}
.building-right {
  position: absolute;
  right: 67px;
  bottom: 0;
  display: block;
  z-index: 8;
}
.building-right span {
  position: absolute;
  right: 18px;
  bottom: 0;
  border: 2px solid #030308;
  background-color: #fff;
  width: 86px;
  height: 24px;
}
.building-right span:nth-child(2) {
  right: 18px;
  bottom: 22px;
  width: 96px;
  height: 22px;
  transform-origin: center bottom;
  transform: skewX(-20deg);
}
.building-right span:nth-child(3) {
  right: 0;
  bottom: 0;
  width: 22px;
  height: 52px;
}
.building-right span:nth-child(4) {
  right: 2px;
  bottom: 42px;
  background-color: #030308;
  border: none;
  width: 7px;
  height: 2px;
}
.building-right span:nth-child(5) {
  right: 13px;
  bottom: 32px;
  background-color: #030308;
  border: none;
  width: 7px;
  height: 2px;
}
.building-right span:nth-child(6) {
  right: 10px;
  bottom: 52px;
  background-color: #030308;
  border: none;
  width: 2px;
  height: 32px;
}
.building-right span:nth-child(7) {
  right: -4px;
  bottom: 74px;
  border: none;
  background-color: transparent;
  width: 0;
  height: 0;
  border-bottom: 10px solid #030308;
  border-left: 0 solid transparent;
  border-right: 15px solid transparent;
  transform: skewY(-15deg);
  transform-origin: left center;
  animation: moveFlag 1.5s ease infinite;
}
.building-right span:nth-child(8) {
  right: 2px;
  bottom: 76px;
  border: none;
  background-color: transparent;
  width: 0;
  height: 0;
  border-bottom: 5px solid #fff;
  border-left: 0 solid transparent;
  border-right: 8px solid transparent;
  transform: skewY(-15deg);
  transform-origin: left center;
  animation: moveFlag 1.5s ease infinite;
}
@keyframes moveFlag {
  0% {
    transform: skewY(-15deg);
  }
  50% {
    transform: skewY(5deg);
  }
  100% {
    transform: skewY(-15deg);
  }
}
.building-right span:nth-child(9) {
  right: 31px;
  bottom: 0;
  width: 33px;
  height: 65px;
  perspective: 70px;
  background-color: transparent;
  border: none;
}
.building-right span:nth-child(9) span {
  right: 0;
  bottom: 0;
  width: 33px;
  height: 65px;
  transform-origin: bottom center;
  transform: rotateX(13deg);
}
.building-right span:nth-child(10) {
  right: 31px;
  bottom: 55px;
  width: 33px;
  height: 10px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.building-right span:nth-child(11) {
  right: 36px;
  bottom: 63px;
  width: 23px;
  height: 30px;
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
}
.building-right span:nth-child(12) {
  right: 42px;
  bottom: 91px;
  width: 11px;
  height: 7px;
}
.building-right span:nth-child(13) {
  right: 46px;
  bottom: 98px;
  width: 3px;
  border: none;
  background-color: #030308;
  height: 18px;
}
.building-right span:nth-child(14) {
  right: 43px;
  bottom: 17px;
  width: 9px;
  height: 11px;
  background: green;
}
.building-right span:nth-child(15) {
  right: 43px;
  bottom: 35px;
  width: 9px;
  height: 11px;
}
.building-right span:nth-child(16) {
  right: 43px;
  bottom: 63px;
  width: 9px;
  height: 13px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  background: orangered;
}
.bird-wrapper {
  position: absolute;
  left: 166px;
  bottom: 46px;
  display: block;
  z-index: 8;
  animation: birdFly 3.5s ease infinite;
  animation-delay: 0.4s;
}
.bird-wrapper.snd {
  left: 340px;
  bottom: 65px;
  animation-delay: 0s;
}
.bird-wrapper.trd {
  left: 420px;
  bottom: 132px;
  animation-delay: 1s;
}
@keyframes birdFly {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0);
  }
}
.left-wing {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 14px;
  height: 14px;
  transform-origin: right bottom;
  animation: wingLeft 0.4s ease infinite;
}
.left-wing span {
  position: absolute;
  right: 0;
  bottom: 0;
  border: 2px solid #030308;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  border-color: #030308 transparent transparent transparent;
  transform: translate(4px, 12px);
}
@keyframes wingLeft {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(40deg);
  }
  100% {
    transform: rotate(0);
  }
}
.right-wing {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 14px;
  height: 14px;
  transform-origin: left bottom;
  animation: wingRight 0.4s ease infinite;
}
.right-wing span {
  position: absolute;
  right: 0;
  bottom: 0;
  border: 2px solid #030308;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  border-color: #030308 transparent transparent transparent;
  transform: translate(-4px, 12px);
}
@keyframes wingRight {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(-40deg);
  }
  100% {
    transform: rotate(0);
  }
}
.sun {
  position: absolute;
  left: 200px;
  top: 50px;
  z-index: 4;
}
.sun span {
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
  border: 2px solid #030308;
  background-color: yellow;
  width: 50px;
  height: 50px;
  animation: sunShine 3.5s linear infinite;
}
@keyframes sunShine {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.14);
  }
  100% {
    transform: scale(1);
  }
}
.cloud-left {
  position: absolute;
  left: 124px;
  top: 113px;
  z-index: 4;
  width: 30px;
  height: 20px;
  animation: cloudMove 5s linear infinite;
}
.cloud-left span {
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #26c6f8;
  border: 2px solid #030308;
  border-color: #030308 #030308 #030308 transparent;
}
.cloud-left span:nth-child(2) {
  right: auto;
  left: 0;
  top: 3px;
  width: 14px;
  height: 17px;
  border-radius: 0;
  border: none;
  border-top: 2px solid #030308;
  border-bottom: 2px solid #030308;
  border-left: 2px solid #030308;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.cloud-left span:nth-child(3) {
  right: 12px;
  top: auto;
  bottom: 0;
  width: 10px;
  height: 8px;
  border-radius: 0;
  border: none;
  border-bottom: 2px solid #030308;
}
@keyframes cloudMove {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0);
  }
}
.cloud-middle {
  position: absolute;
  left: 285px;
  top: 117px;
  z-index: 4;
  width: 40px;
  height: 28px;
  animation: cloudMove 5s linear infinite;
  animation-delay: 0.4s;
}
.cloud-middle span {
  position: absolute;
  right: 0;
  top: 0;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #26c6f8;
  border: 2px solid #030308;
  border-color: #030308 #030308 #030308 transparent;
}
.cloud-middle span:nth-child(2) {
  right: auto;
  left: 0;
  top: 4px;
  width: 17px;
  height: 24px;
  border-radius: 0;
  border: none;
  border-top: 2px solid #030308;
  border-bottom: 2px solid #030308;
  border-left: 2px solid #030308;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
.cloud-middle span:nth-child(3) {
  right: 14px;
  top: auto;
  bottom: 0;
  width: 10px;
  height: 8px;
  border-radius: 0;
  border: none;
  border-bottom: 2px solid #030308;
}
.cloud-right {
  position: absolute;
  right: 64px;
  top: 73px;
  z-index: 4;
  width: 23px;
  height: 16px;
  animation: cloudMove 5s linear infinite;
  animation-delay: 0.6s;
}
.cloud-right span {
  position: absolute;
  right: 0;
  top: 0;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #26c6f8;
  border: 2px solid #030308;
  border-color: #030308 #030308 #030308 transparent;
}
.cloud-right span:nth-child(2) {
  right: auto;
  left: 0;
  top: 3px;
  width: 10px;
  height: 13px;
  border-radius: 0;
  border: none;
  border-top: 2px solid #030308;
  border-bottom: 2px solid #030308;
  border-left: 2px solid #030308;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.cloud-right span:nth-child(3) {
  right: 7px;
  top: auto;
  bottom: 0;
  width: 8px;
  height: 8px;
  border-radius: 0;
  border: none;
  border-bottom: 2px solid #030308;
}
.section-down {
  position: absolute;
  width: 100%;
  height: 180px;
  left: 0;
  top: 270px;
  display: block;
  z-index: 6;
  border-top: 2px solid #030308;
  background-color: #0e87cc;
}
.water-wrapper {
  position: absolute;
  left: 65px;
  bottom: 0;
  height: 70px;
  width: 77px;
  display: block;
  z-index: 6;
  overflow: hidden;
}
.water-wrapper.right {
  left: 450px;
  bottom: -20px;
}
.water-wrapper.top.right {
  left: 454.5px;
  bottom: -10px;
}
.water-wrap {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 70px;
  width: 231px;
  display: block;
  z-index: 6;
  transform: translateX(0);
  animation: moveWater 1.6s linear infinite;
}
@keyframes moveWater {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-33.333333333333%);
  }
}
.water {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 70px;
  width: 77px;
  display: block;
  z-index: 6;
  background-color: #0e87cc;
}
.water.snd {
  left: 77px;
}
.water.trd {
  left: 154px;
}
.water span {
  position: absolute;
  left: -4px;
  top: 12px;
  height: 20px;
  width: 20px;
  display: block;
  z-index: 6;
  border-radius: 50%;
  border: 2px solid #030308;
  border-color: #030308 transparent transparent transparent;
}
.water span:nth-child(2) {
  left: 9px;
  top: -1px;
  border-color: transparent transparent #030308 transparent;
}
.water span:nth-child(3) {
  left: 22px;
}
.water span:nth-child(4) {
  left: 35px;
  top: -1px;
  border-color: transparent transparent #030308 transparent;
}
.water span:nth-child(5) {
  left: 48px;
}
.water span:nth-child(6) {
  left: 61px;
  top: -1px;
  border-color: transparent transparent #030308 transparent;
}
.water-wrapper.top {
  width: 68px;
  left: 69.5px;
  bottom: 10px;
  z-index: 7;
}
.water-wrapper.top .water-wrap {
  width: 204px;
}
.water-wrapper.top .water {
  height: 70px;
  width: 68px;
  background-color: transparent;
}
.water-wrapper.top .water.snd {
  left: 68px;
}
.water-wrapper.top .water.trd {
  left: 136px;
}
.water-wrapper.top .water span {
  position: absolute;
  left: -3px;
  top: 12px;
  height: 18px;
  width: 18px;
  display: block;
  z-index: 6;
  border-radius: 50%;
  border: 2px solid #030308;
  border-color: #030308 transparent transparent transparent;
}
.water-wrapper.top .water span:nth-child(2) {
  left: 8px;
  top: 0;
  border-color: transparent transparent #030308 transparent;
}
.water-wrapper.top .water span:nth-child(3) {
  left: 19px;
}
.water-wrapper.top .water span:nth-child(4) {
  left: 30px;
  top: 0;
  border-color: transparent transparent #030308 transparent;
}
.water-wrapper.top .water span:nth-child(5) {
  left: 41px;
}
.water-wrapper.top .water span:nth-child(6) {
  left: 53px;
  top: 0;
  border-color: transparent transparent #030308 transparent;
}
.fish-wrapper {
  position: absolute;
  left: 82px;
  bottom: 85px;
  height: 20px;
  width: 54px;
  display: block;
  z-index: 4;
}
.fish-wrapper.right {
  left: 467px;
  bottom: 70px;
}
.fish-wrapper.right .fish-wrap {
  transform: translate(-35px, 60px) rotate(-90deg);
  animation: moveFish-higher 3.5s linear infinite;
  animation-delay: 1s;
}
.fish-wrap {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 20px;
  width: 54px;
  display: block;
  z-index: 4;
  transform-origin: center center;
  transform: translate(-35px, 60px) rotate(-90deg);
  animation: moveFish 3.5s linear infinite;
}
@keyframes moveFish {
  0% {
    transform: translate(-35px, 60px) rotate(-90deg);
  }
  5% {
    transform: translate(20px, 60px) rotate(90deg);
  }
  45% {
    transform: translate(20px, 60px) rotate(90deg);
  }
  50% {
    transform: translate(10px, 10px) rotate(35deg);
  }
  58% {
    transform: translate(0, 0) rotate(0);
  }
  67% {
    transform: translate(0, 0) rotate(0);
  }
  75% {
    transform: translate(-20px, 10px) rotate(-65deg);
  }
  80% {
    transform: translate(-35px, 60px) rotate(-90deg);
  }
  100% {
    transform: translate(-35px, 60px) rotate(-90deg);
  }
}
@keyframes moveFish-higher {
  0% {
    transform: translate(-35px, 60px) rotate(-90deg);
  }
  5% {
    transform: translate(20px, 60px) rotate(90deg);
  }
  45% {
    transform: translate(20px, 60px) rotate(90deg);
  }
  50% {
    transform: translate(10px, 10px) rotate(35deg);
  }
  58% {
    transform: translate(0, -7px) rotate(0);
  }
  67% {
    transform: translate(0, -7px) rotate(0);
  }
  75% {
    transform: translate(-20px, 10px) rotate(-65deg);
  }
  80% {
    transform: translate(-35px, 60px) rotate(-90deg);
  }
  100% {
    transform: translate(-35px, 60px) rotate(-90deg);
  }
}
.fish {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 20px;
  width: 54px;
  display: block;
  z-index: 4;
  transform: scale(1, 0.45);
}
.fish span {
  position: absolute;
  left: 0;
  top: 0;
  height: 36px;
  width: 36px;
  background-color: #fff;
  border: 4px solid #030308;
  border-top-left-radius: 60%;
  border-bottom-right-radius: 60%;
  border-bottom-left-radius: 53%;
  transform: rotate(45deg);
}
.fish span:nth-child(2) {
  position: absolute;
  left: 39px;
  top: 16px;
  height: 4px;
  width: 13px;
  background-color: #030308;
  border: none;
  border-radius: 0;
  transform-origin: left center;
  transform: rotate(45deg);
  animation: fishTail-bottom 3.5s linear infinite;
}
.fish-wrapper.right .fish span:nth-child(2) {
  animation-delay: 1s;
}
@keyframes fishTail-bottom {
  0% {
    transform: rotate(45deg);
    left: 39px;
    top: 16px;
  }
  59% {
    transform: rotate(45deg);
    left: 39px;
    top: 16px;
  }
  60% {
    transform: rotate(25deg);
    left: 37px;
    top: 15px;
  }
  61% {
    transform: rotate(25deg);
    left: 37px;
    top: 15px;
  }
  62% {
    transform: rotate(45deg);
    left: 39px;
    top: 16px;
  }
  64% {
    transform: rotate(45deg);
    left: 39px;
    top: 16px;
  }
  65% {
    transform: rotate(25deg);
    left: 37px;
    top: 15px;
  }
  66% {
    transform: rotate(25deg);
    left: 37px;
    top: 15px;
  }
  67% {
    transform: rotate(45deg);
    left: 39px;
    top: 16px;
  }
  100% {
    transform: rotate(45deg);
    left: 39px;
    top: 16px;
  }
}
.fish span:nth-child(3) {
  position: absolute;
  left: 39px;
  top: 16px;
  height: 4px;
  width: 13px;
  background-color: #030308;
  border: none;
  border-radius: 0;
  transform-origin: left center;
  transform: rotate(-45deg);
  animation: fishTail-top 3.5s linear infinite;
}
.fish-wrapper.right .fish span:nth-child(3) {
  animation-delay: 1s;
}
@keyframes fishTail-top {
  0% {
    transform: rotate(-45deg);
    left: 39px;
    top: 16px;
  }
  59% {
    transform: rotate(-45deg);
    left: 39px;
    top: 16px;
  }
  60% {
    transform: rotate(-25deg);
    left: 37px;
    top: 17px;
  }
  61% {
    transform: rotate(-25deg);
    left: 37px;
    top: 17px;
  }
  62% {
    transform: rotate(-45deg);
    left: 39px;
    top: 16px;
  }
  64% {
    transform: rotate(-45deg);
    left: 39px;
    top: 16px;
  }
  65% {
    transform: rotate(-25deg);
    left: 37px;
    top: 17px;
  }
  66% {
    transform: rotate(-25deg);
    left: 37px;
    top: 17px;
  }
  67% {
    transform: rotate(-45deg);
    left: 39px;
    top: 16px;
  }
  100% {
    transform: rotate(-45deg);
    left: 39px;
    top: 16px;
  }
}
.fish span:nth-child(4) {
  position: absolute;
  left: 9px;
  top: 14px;
  height: 7px;
  width: 4px;
  background-color: #030308;
  border: none;
  border-radius: 50%;
  transform-origin: center center;
  transform: rotate(0);
  animation: fishEye 3.5s linear infinite;
}
.fish-wrapper.right .fish span:nth-child(4) {
  animation-delay: 1s;
}
@keyframes fishEye {
  0% {
    height: 7px;
    top: 14px;
  }
  59% {
    height: 7px;
    top: 14px;
  }
  60% {
    height: 0;
    top: 17px;
  }
  61% {
    height: 7px;
    top: 14px;
  }
  64% {
    height: 7px;
    top: 14px;
  }
  65% {
    height: 0;
    top: 17px;
  }
  66% {
    height: 7px;
    top: 14px;
  }
  100% {
    height: 7px;
    top: 14px;
  }
}
.water-lines-wrapper {
  position: absolute;
  top: 18px;
  left: 33px;
  z-index: 2;
}
.water-lines-wrapper.right {
  left: 500px;
  top: 24px;
}
.water-lines {
  position: absolute;
  top: 0;
  left: 0;
}
.water-lines span {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 2px;
  background-color: #030308;
  animation: water-lines-1 3.5s ease infinite;
}
@keyframes water-lines-1 {
  0% {
    transform: translateX(0) scaleX(1);
  }
  50% {
    transform: translateX(21px) scaleX(0.75);
  }
  100% {
    transform: translateX(0) scaleX(1);
  }
}
.water-lines span:nth-child(2) {
  left: 49px;
  width: 17px;
  animation-name: water-lines-2;
}
@keyframes water-lines-2 {
  0% {
    transform: translateX(0) scaleX(1);
  }
  50% {
    transform: translateX(15px) scaleX(1.29);
  }
  100% {
    transform: translateX(0) scaleX(1);
  }
}
.water-lines span:nth-child(3) {
  top: 12px;
  left: 14px;
  width: 14px;
  animation-name: water-lines-3;
  animation-delay: 0.1s;
}
@keyframes water-lines-3 {
  0% {
    transform: translateX(0) scaleX(1);
  }
  50% {
    transform: translateX(8px) scaleX(1.21);
  }
  100% {
    transform: translateX(0) scaleX(1);
  }
}
.water-lines span:nth-child(4) {
  top: 12px;
  left: 47px;
  width: 11px;
  animation-name: water-lines-4;
  animation-delay: 0.1s;
}
@keyframes water-lines-4 {
  0% {
    transform: translateX(0) scaleX(1);
  }
  50% {
    transform: translateX(11px) scaleX(1.18);
  }
  100% {
    transform: translateX(0) scaleX(1);
  }
}
.water-lines span:nth-child(5) {
  top: 24px;
  left: 32px;
  width: 10px;
  animation-name: water-lines-5;
  animation-delay: 0.2s;
}
@keyframes water-lines-5 {
  0% {
    transform: translateX(0) scaleX(1);
  }
  50% {
    transform: translateX(7px) scaleX(1.2);
  }
  100% {
    transform: translateX(0) scaleX(1);
  }
}
.water-big-wrapper {
  position: absolute;
  left: 240px;
  bottom: 0;
  height: 140px;
  width: 114px;
  display: block;
  z-index: 6;
  overflow: hidden;
}
.water-big-wrap {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 114px;
  width: 348px;
  display: block;
  z-index: 6;
  animation: moveWater 2s linear infinite;
}
.water-big {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 140px;
  width: 116px;
  display: block;
  z-index: 1;
}
.water-big.snd {
  left: 116px;
}
.water-big.trd {
  left: 232px;
}
.water-big span {
  position: absolute;
  left: 0;
  top: 20px;
  height: 50px;
  width: 116px;
  display: block;
  z-index: 1;
  background-color: #0e87cc;
}
.water-big span:nth-child(2) {
  left: -2px;
  top: -20px;
  height: 40px;
  width: 40px;
  z-index: 2;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid #030308;
  border-color: transparent #030308 #030308 transparent;
  transform: rotate(45deg);
}
.water-big span:nth-child(3) {
  left: 37px;
  top: -20px;
  height: 40px;
  width: 40px;
  z-index: 2;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid #030308;
  border-color: transparent #030308 #030308 transparent;
  transform: rotate(45deg);
}
.water-big span:nth-child(4) {
  left: 75px;
  top: -20px;
  height: 40px;
  width: 40px;
  z-index: 2;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid #030308;
  border-color: transparent #030308 #030308 transparent;
  transform: rotate(45deg);
}
.water-big span:nth-child(5) {
  left: 8px;
  top: 14px;
  height: 60px;
  width: 60px;
  border-top-left-radius: 60%;
  border-bottom-right-radius: 60%;
  border-bottom-left-radius: 53%;
  transform: scale(0.45, 1) rotate(-45deg);
}
.water-big span:nth-child(6) {
  left: 46px;
  top: 14px;
  height: 60px;
  width: 60px;
  border-top-left-radius: 60%;
  border-bottom-right-radius: 60%;
  border-bottom-left-radius: 53%;
  transform: scale(0.45, 1) rotate(-45deg);
}
.water-big span:nth-child(7) {
  left: 84px;
  top: 14px;
  height: 60px;
  width: 60px;
  border-top-left-radius: 60%;
  border-bottom-right-radius: 60%;
  border-bottom-left-radius: 53%;
  transform: scale(0.45, 1) rotate(-45deg);
}
.water-big span:nth-child(8) {
  left: -30px;
  top: 14px;
  height: 60px;
  width: 60px;
  border-top-left-radius: 60%;
  border-bottom-right-radius: 60%;
  border-bottom-left-radius: 53%;
  transform: scale(0.45, 1) rotate(-45deg);
}
.water-big span:nth-child(9) {
  left: 5px;
  top: 15px;
  height: 15px;
  width: 10px;
  transform: rotate(-55deg);
}
.water-big span:nth-child(10) {
  left: 43px;
  top: 14px;
  height: 15px;
  width: 10px;
  transform: rotate(-55deg);
}
.water-big span:nth-child(11) {
  left: 81px;
  top: 14px;
  height: 15px;
  width: 10px;
  transform: rotate(-55deg);
}
.water-big span:nth-child(12) {
  left: 23px;
  top: 13px;
  height: 17px;
  width: 10px;
  transform: rotate(55deg);
}
.water-big span:nth-child(13) {
  left: 62px;
  top: 14px;
  height: 15px;
  width: 10px;
  transform: rotate(55deg);
}
.water-big span:nth-child(14) {
  left: 100px;
  top: 14px;
  height: 15px;
  width: 10px;
  transform: rotate(55deg);
}
.ship-wrapper {
  position: absolute;
  left: 260px;
  bottom: 116px;
  display: block;
  z-index: 4;
}
.ship {
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  z-index: 6;
  width: 86px;
  height: 34px;
  transform-origin: center center;
  animation: shipFloat 1.6s linear infinite;
}
@keyframes shipFloat {
  0% {
    transform: translateY(0) rotate(0);
  }
  25% {
    transform: translateY(0) rotate(-10deg);
  }
  40% {
    transform: translateY(4px) rotate(-10deg);
  }
  85% {
    transform: translateY(1px) rotate(10deg);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}
.ship span {
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  width: 86px;
  height: 34px;
  border: 3px solid #030308;
  border-bottom-right-radius: 40px;
  border-bottom-left-radius: 20px;
  background-color: orange;
  z-index: 1;
}
.ship span:nth-child(2) {
  left: 30px;
  bottom: 34px;
  width: 3px;
  height: 85px;
  border: none;
  border-radius: 0;
  background-color: #030308;
}
.ship span:nth-child(3) {
  left: 21px;
  bottom: 57px;
  width: 0;
  height: 0;
  border: none;
  border-radius: 0;
  background-color: transparent;
  border-bottom: 62px solid #030308;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  transform: rotate(-16.5deg);
  border-bottom-right-radius: 5px;
}
.ship span:nth-child(4) {
  left: 21px;
  bottom: 55px;
  width: 0;
  height: 0;
  border: none;
  border-radius: 0;
  background-color: transparent;
  border-bottom: 62px solid #fff;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  transform: rotate(-17.5deg) scale(0.85);
  border-bottom-right-radius: 3px;
  z-index: 2;
}
.ship span:nth-child(5) {
  left: -1px;
  bottom: 60px;
  width: 0;
  height: 0;
  border: none;
  border-radius: 0;
  background-color: transparent;
  border-bottom: 48px solid #030308;
  border-left: 31px solid transparent;
  border-right: 0 solid transparent;
  border-bottom-left-radius: 5px;
}
.ship span:nth-child(6) {
  left: 1px;
  bottom: 58px;
  width: 0;
  height: 0;
  border: none;
  border-radius: 0;
  background-color: transparent;
  border-bottom: 48px solid #fff;
  border-left: 31px solid transparent;
  border-right: 0 solid transparent;
  border-bottom-left-radius: 3px;
  transform: scale(0.8);
  z-index: 2;
}
</style>
<template>
  <div class="container mt-4">
    <ul class="nav nav-tabs justify-content-center" id="myTab" role="tablist">
      <li
        class="nav-item"
        role="presentation"
        @click="$refs.textTabButton.click()"
      >
        <button
          ref="textTabButton"
          style="width: 100%"
          class="nav-link active bubbly-button"
          id="text-tab"
          data-bs-toggle="tab"
          data-bs-target="#text"
          type="button"
          role="tab"
          aria-controls="text"
          aria-selected="true"
          @click="setCurrentTab('text')"
        >
          <asg-ths
            v-show="currentFocusedTab === 'text'"
            :texts="['图文资料']"
          />
          <span v-show="currentFocusedTab !== 'text'">图文资料</span>
        </button>
      </li>

      <li
        class="nav-item"
        role="presentation"
        @click="$refs.videoTabButton.click()"
      >
        <button
          ref="videoTabButton"
          style="width: 100%"
          class="nav-link bubbly-button"
          id="video-tab"
          data-bs-toggle="tab"
          data-bs-target="#video"
          type="button"
          role="tab"
          aria-controls="video"
          aria-selected="false"
          @click="setCurrentTab('video')"
        >
          <asg-ths
            v-show="currentFocusedTab === 'video'"
            :texts="['视频资料']"
          />
          <span v-show="currentFocusedTab !== 'video'">视频资料</span>
        </button>
      </li>
      <li
        class="nav-item"
        role="presentation"
        @click="$refs.qualificationTabButton.click()"
      >
        <button
          ref="qualificationTabButton"
          style="width: 100%"
          class="nav-link bubbly-button"
          id="qualification-tab"
          data-bs-toggle="tab"
          data-bs-target="#qualification"
          type="button"
          role="tab"
          aria-controls="qualification"
          aria-selected="false"
          @click="setCurrentTab('qualification')"
        >
          <asg-ths
            v-show="currentFocusedTab === 'qualification'"
            :texts="['相关资质']"
          />
          <span v-show="currentFocusedTab !== 'qualification'">相关资质</span>
        </button>
      </li>
      <li
        class="nav-item"
        role="presentation"
        @click="$refs.learningTabButton.click()"
      >
        <button
          ref="learningTabButton"
          style="width: 100%"
          class="nav-link bubbly-button"
          id="learning-tab"
          data-bs-toggle="tab"
          data-bs-target="#learning"
          type="button"
          role="tab"
          aria-controls="learning"
          aria-selected="false"
          @click="setCurrentTab('learning')"
        >
          <asg-ths
            v-show="currentFocusedTab === 'learning'"
            :texts="['学习园地']"
          />
          <span v-show="currentFocusedTab !== 'learning'">学习园地</span>
        </button>
      </li>
    </ul>
    <!-- Tabs Content -->
    <div class="tab-content mt-4">
      <!-- 图文资料 Tab -->
      <div
        class="tab-pane fade show active"
        id="text"
        role="tabpanel"
        aria-labelledby="text-tab"
      >
        <div class="row row-cols-1 row-cols-md-2 g-4">
          <div v-for="(item, index) in textItems" :key="index" class="col">
            <router-link :to="item.route" class="text-decoration-none">
              <div class="card h-100 border-0 shadow-sm rounded">
                <div
                  class="card-body d-flex flex-column justify-content-between"
                >
                  <div>
                    <h5 class="card-title">{{ item.title }}</h5>
                  </div>
                  <img
                    v-lazy="item.image"
                    class="card-img-top img-fluid"
                    alt=""
                    style="height: 150px; object-fit: cover"
                  />
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>

      <!-- 视频资料 Tab -->
      <div
        class="tab-pane fade"
        id="video"
        role="tabpanel"
        aria-labelledby="video-tab"
      >
        <div class="row justify-content-center mt-4">
          <VideoDisplay />
        </div>
      </div>

      <!-- 相关资质 Tab -->
      <div
        class="tab-pane fade"
        id="qualification"
        role="tabpanel"
        aria-labelledby="qualification-tab"
      >
        <!-- <asgsty></asgsty> -->
        <QualificationDisplay />
      </div>
      <!-- 学习园地 Tab -->
      <div
        class="tab-pane fade"
        id="learning"
        role="tabpanel"
        aria-labelledby="learning-tab"
      >
        <StudyVideo />
      </div>
    </div>
  </div>
</template>

<script>
import VideoDisplay from "@/components/VideoDisplay.vue";
import QualificationDisplay from "@/components/QualificationDisplay.vue";
import StudyVideo from "@/components/StudyVideo.vue";
import AsgThs from "@/components/tks.vue"; // 引入公共组件
// import asgsty from "@/components/sty.vue";
export default {
  name: "AsgContentList",
  components: {
    VideoDisplay,
    QualificationDisplay,
    StudyVideo,
    AsgThs,
    // asgsty
  },
  data() {
    return {
      currentFocusedTab: "text",
      textItems: [
        {
          title: "企业介绍",
          subtitle: "",
          image: require("@/assets/img/bg/ds.png"),
          route: "/Company",
        },
        {
          title: "行业分析",
          subtitle: "",
          image: require("@/assets/img/bg/bg11.jpg"),
          route: "/Analysis",
        },
        {
          title: "服务对象",
          subtitle: "",
          image: require("@/assets/img/fwdx/home-fwdx.png"),
          route: "/Services",
        },
        {
          title: "供应链介绍",
          subtitle: "",
          image: require("@/assets/img/bg/bg8.jpg"),
          route: "/Supply",
        },
        {
          title: "核心优势",
          subtitle: "",
          image: require("@/assets/img/home/C4.jpg"),
          route: "/Advantage",
        },
        {
          title: "联盟商家",
          subtitle: "",
          image: require("@/assets/img/bg/bg12.jpg"),
          route: "/Alliance",
        },
      ],
    };
  },
  mounted() {
    const animateButton = (e) => {
      e.preventDefault();
      e.target.classList.remove("animate");
      e.target.classList.add("animate");
      setTimeout(() => {
        e.target.classList.remove("animate");
      }, 700);
    };

    const bubblyButtons = document.querySelectorAll(".bubbly-button");
    bubblyButtons.forEach((button) => {
      button.addEventListener("click", animateButton, false);
    });
  },
  methods: {
    setCurrentTab(tab) {
      this.currentFocusedTab = tab;
    },
  },
};
</script>

<style scoped lang="scss">
.card {
  transition: transform 0.2s;
  background-color: rgba(255, 255, 255, 0.3);
}

.card:hover {
  transform: scale(1.05);
}

.nav-link {
  color: #333 !important;
  font-size: 1rem;
  font-weight: bold;
  padding: 8px 4px;
}

.nav-link.active {
  color: #ffa500 !important;
  border-color: transparent;
  border-bottom-color: #ffa500 !important;
  background-color: rgba(255, 255, 255, 0.6) !important;
}

.nav-tabs {
  border-bottom: 2px solid #ddd;
  display: flex;
}

.nav-item {
  flex: 1;
}

.card-title {
  color: #333;
  font-size: 24px;
  font-weight: bold;
}

.card-text {
  color: #666;
  font-size: 16px;
}

.card-img-top {
  border-radius: 15px;
}

@media (max-width: 768px) {
  .card-title {
    font-size: 1rem;
  }

  .card-text {
    font-size: 1rem;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Dongle:wght@300&display=swap");

$button-bg: #ff0081;
$button-text-color: #fff;

.bubbly-button {
  display: inline-block;
  color: $button-text-color;
  border: none;
  cursor: pointer;
  position: relative;
  transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;
}

.bubbly-button:focus {
  outline: 0;
}

.bubbly-button:before,
.bubbly-button:after {
  position: absolute;
  content: "";
  display: block;
  width: 150%;
  height: 100%;
  left: 30%;
  z-index: -1000;
  transition: all ease-in-out 0.5s;
  background-repeat: no-repeat;
}

.bubbly-button:before {
  display: none;
  top: -70%;
  background-image: radial-gradient(circle, $button-bg 20%, transparent 20%),
    radial-gradient(circle, transparent 20%, $button-bg 20%, transparent 30%);
  background-size: 10% 10%, 20% 20%;
}

.bubbly-button:after {
  display: none;
  bottom: -70%;
  background-image: radial-gradient(circle, $button-bg 20%, transparent 20%),
    radial-gradient(circle, transparent 20%, $button-bg 20%, transparent 30%);
  background-size: 10% 10%, 20% 20%;
}

.bubbly-button:active {
  transform: scale(0.9);

  box-shadow: 0 2px 25px rgba(255, 0, 130, 0.2);
}

.bubbly-button.animate:before {
  display: block;
  animation: topBubbles 0.75s ease-in-out forwards;
}

.bubbly-button.animate:after {
  display: block;
  animation: bottomBubbles 0.75s ease-in-out forwards;
}

@keyframes topBubbles {
  0% {
    background-position: 5% 90%, 10% 90%;
  }
  50% {
    background-position: 0% 80%, 0% 20%;
  }
  100% {
    background-position: 0% 70%, 0% 10%;
    background-size: 0% 0%, 0% 0%;
  }
}

@keyframes bottomBubbles {
  0% {
    background-position: 10% -10%, 30% 10%;
  }
  50% {
    background-position: 0% 80%, 20% 80%;
  }
  100% {
    background-position: 0% 90%, 20% 90%;
    background-size: 0% 0%, 0% 0%;
  }
}
</style>

<template>
  <div
    id="carouselExampleFade"
    class="carousel slide carousel-fade dark-wrapper"
    data-bs-ride="carousel"
    data-speed="0.7"
  >
    <div class="carousel-inner">
      <!-- 使用v-for循环渲染轮播项 -->
      <div
        v-for="(item, index) in items"
        :key="index"
        :class="['carousel-item', { active: index === indexs }]"
      >
        <img v-lazy="item.src" alt="" class="d-block w-100" />
      </div>
    </div>
    <div class="carousel-progress">
      <div class="carousel-progress-bar" ref="progressBar"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AsgCarousel",
  props: {
    items: {
      type: Array,
      required: true,
    },
    indexs: {
      type: Number,
      required: true,
    },
  },
  mounted() {
    const progressBar = this.$refs.progressBar;
    const interval = 5000; // 轮播间隔时间，单位毫秒
    const updateInterval = 100; // 更新进度条的频率，单位毫秒
    const progressStep = (100 / interval) * updateInterval; // 每次更新的进度百分比

    let progress = 0;
    const updateProgressBar = () => {
      progressBar.style.width = `${progress}%`;
      progress += progressStep; // 增加进度条宽度
      if (progress >= 100) {
        progress = 0; // 重置进度
      }
    };

    this.timer = setInterval(updateProgressBar, updateInterval);

    // 在轮播切换时重置进度条
    this.$el.addEventListener('slid.bs.carousel', () => {
      progressBar.style.width = '0%'; // 轮播切换时重置进度条
    });
  },
  beforeUnmount() {
    clearInterval(this.timer); // 清除定时器
  }
};
</script>

<style scoped>
.carousel-item img {
  height: 400px;
  object-fit: cover;
}
.carousel-progress {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1050; /* 确保在轮播的上层 */
}

.carousel-progress-bar {
  height: 100%;
  background-color: #ffa500; /* 设置进度条颜色 */
  transition: width 0.1s linear; /* 进度条动画效果 */
}
</style>

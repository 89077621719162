<template>
  <div class="game-container">
    <div class="navigation">
      <button @click="switchToConsumerGame" :class="{ active: showConsumerGame }">消费者模拟</button>
      <button @click="switchToMerchantGame" :class="{ active: !showConsumerGame }">商家模拟</button>
    </div>

    <div v-if="showConsumerGame" class="consumer-game">
      <h1>消费者模拟</h1>
      <div class="status">
        <p>人民币余额: ¥{{ money }}</p>
        <p>积分余额: {{ points }}</p>
        <p>虚拟货币 ACC: {{ acc.toFixed(2) }}</p>
      </div>
      <div class="shop">
        <h2>商品列表</h2>
        <div v-for="item in items" :key="item.id" class="item">
          <p>{{ item.name }} - ¥{{ item.price }}</p>
          <button @click="buyItem(item)" class="buy-button">购买</button>
        </div>
      </div>
      <div class="actions">
        <button @click="convertPointsToAcc" class="convert-button">积分转化为 ACC</button>
      </div>
      <div class="events">
        <p v-if="eventMessage">{{ eventMessage }}</p>
      </div>
    </div>

    <div v-else class="merchant-game">
      <h1>商家模拟</h1>
      <div class="status">
        <p>今日销售额: ¥{{ sales }}</p>
        <p>选择核销比例: {{ writeOffPercentage }}%</p>
        <p>核销积分: {{ pointsToWriteOff }}</p>
        <p>虚拟货币 ACC: {{ acc.toFixed(2) }}</p>
      </div>
      <div class="sales">
        <h2>设置销售额</h2>
        <input v-model.number="sales" type="number" placeholder="输入今日销售额" class="input-field" />
        <h2>选择核销比例</h2>
        <input v-model.number="writeOffPercentage" type="number" min="10" max="30" step="1" class="input-field" />
        <button @click="calculatePointsToWriteOff" class="calculate-button">计算核销积分</button>
      </div>
      <div class="actions">
        <button @click="writeOffPoints" class="write-off-button">核销积分</button>
      </div>
      <div class="events">
        <p v-if="eventMessage">{{ eventMessage }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showConsumerGame: true, // 默认显示消费者模拟
      money: 1000, // 初始人民币余额
      points: 0, // 初始积分
      acc: 0, // 初始虚拟货币 ACC
      accValueMultiplier: 0.1, // 积分转化为 ACC 的增值系数
      items: [
        { id: 1, name: '商品 A', price: 100 },
        { id: 2, name: '商品 B', price: 200 },
        { id: 3, name: '商品 C', price: 300 }
      ],
      sales: 1000, // 初始销售额
      writeOffPercentage: 10, // 默认核销比例
      pointsToWriteOff: 0, // 需要核销的积分
      eventMessage: ''
    };
  },
  methods: {
    switchToConsumerGame() {
      this.showConsumerGame = true;
      this.clearEventMessage();
    },
    switchToMerchantGame() {
      this.showConsumerGame = false;
      this.clearEventMessage();
    },
    clearEventMessage() {
      this.eventMessage = '';
    },

    // 消费者模拟方法
    buyItem(item) {
      if (this.money >= item.price) {
        this.money -= item.price;
        const pointsEarned = item.price / 10; // 假设每花费 10 元获得 1 积分
        this.points += pointsEarned;
        this.eventMessage = `购买成功！获得 ${pointsEarned} 积分。`;
      } else {
        this.eventMessage = '余额不足，请充值！';
      }
    },
    convertPointsToAcc() {
      if (this.points > 0) {
        const accGained = this.points * this.accValueMultiplier;
        this.acc += accGained;
        this.points = 0; // 转化后清零积分
        this.eventMessage = `积分转化成功！获得 ${accGained.toFixed(2)} ACC。`;
      } else {
        this.eventMessage = '没有积分可供转化！';
      }
    },

    // 商家模拟方法
    calculatePointsToWriteOff() {
      if (this.sales > 0 && this.writeOffPercentage >= 10 && this.writeOffPercentage <= 30) {
        const amountToWriteOff = this.sales * (this.writeOffPercentage / 100);
        this.pointsToWriteOff = amountToWriteOff / 10; // 每 10 元核销 1 积分
        this.eventMessage = `需要核销积分: ${this.pointsToWriteOff.toFixed(2)} 积分。`;
      } else {
        this.eventMessage = '请确保销售额和核销比例在有效范围内。';
      }
    },
    writeOffPoints() {
      if (this.pointsToWriteOff > 0) {
        const accGained = this.pointsToWriteOff * this.accValueMultiplier;
        this.acc += accGained;
        this.pointsToWriteOff = 0; // 核销后清零
        this.eventMessage = `积分核销成功！获得 ${accGained.toFixed(2)} ACC。`;
      } else {
        this.eventMessage = '没有积分可供核销！';
      }
    }
  }
};
</script>

<style scoped>
.game-container {
  text-align: center;
  font-family: Arial, sans-serif;

  padding: 20px;
}

.navigation {
  margin: 20px;
  display: flex;
  justify-content: center;
}

.navigation button {
  margin: 0 10px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.navigation button.active {
  background-color: #007bff;
  color: white;
}

.consumer-game, .merchant-game {
  margin: 20px;
  padding: 20px;

  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.status {
  margin-bottom: 20px;
}

.shop, .sales {
  margin-bottom: 20px;
}

.item {
  margin: 10px;
  padding: 10px;
  background-color: #e9ecef;
  border-radius: 4px;
}

.actions button, .calculate-button, .write-off-button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.buy-button {
  background-color: #28a745;
  color: white;
}

.convert-button {
  background-color: #007bff;
  color: white;
}

.calculate-button {
  background-color: #ffc107;
  color: white;
}

.write-off-button {
  background-color: #dc3545;
  color: white;
}

.item:hover, .actions button:hover {
  background-color: #007bff;
  color: white;
}

.input-field {
  padding: 10px;
  font-size: 16px;
  margin: 10px 0;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.events p {
  font-weight: bold;
}
</style>
<template>
  <div class="content-section common">
    <Carousel :items="carouselItems" :indexs="currentImageIndex" />
    <div class="container fadeIn my-5">
      <div class="icons-row text-center">
        <div
          v-for="(item, index) in items"
          :key="item.title"
          @click="selectItem(index)"
          class="icon-title-wrapper"
          :class="{ active: selected === index }"
        >
          <div class="icon">{{ item.icon }}</div>
          <!-- 使用 v-show 切换 asg-ths 组件和普通文本 -->
          <asg-ths v-show="selected === index" :texts="[item.title]" class="title" />
          <div v-show="selected !== index" class="title">{{ item.title }}</div>
        </div>
      </div>
      <transition name="fade" mode="out-in">
        <div class="content" :key="selected" id="content-focus">
          <div class="text-bubble">
            <img
              v-lazy="items[selected].image"
              alt=""
              @click="expandImage(items[selected].image)"
              class="clickable-image card"
            />
            <p style="margin-top: 20px">{{ items[selected].description }}</p>
          </div>
        </div>
      </transition>
    </div>
    <div
      class="image-expand-overlay"
      v-if="expandedImage"
      @click="expandedImage = ''"
    >
      <img v-lazy="expandedImage" alt="" class="expanded-image" />
    </div>
  </div>
</template>

<script>
import Carousel from "@/components/Carousel.vue";
import AsgThs from "@/components/tks.vue";  // 引入 asg-ths 组件

export default {
  name: "AsgAnalysis",
  components: {
    Carousel,
    AsgThs,  // 注册组件
  },
  data() {
    return {
      carouselItems: [
        { src: require("@/assets/img/home/C4.jpg") },
        { src: require("@/assets/img/bg/bg123.jpg") },
        { src: require("@/assets/img/bg/bg77.jpg") },
        { src: require("@/assets/img/bg/bg2.jpg") },
        { src: require("@/assets/img/bg/bg124.jpg") },
        { src: require("@/assets/img/bg/bg125.jpg") },
      ],
      currentImageIndex: 0,
      selected: 0,
      expandedImage: "",
      items: [
        {
          icon: "🌐",
          title: "WEB3 行业",
          description:
            "WEB3 行业是下一代互联网的重要发展方向，突破现有互联网瓶颈，可在技术、经济、治理等方面实现现有互联网瓶颈的突破。在保障用户身份和数据自主管理权的前提下，通过共识算法、智能合约、加密通信等技术，在互联的效率和权益的公平之间取得平衡，并探索数据价值的发挥。行业整合趋势：有观点认为 2024 年将是 WEB3 行业增长和整合的一年，例如：人工智能与区块链的交叉可能会利用人工智能增强 WEB3 的用户体验和效率，同时区块链技术可作为人工智能的护栏和透明层。",
          image: require("@/assets/img/bg/web3.png"),
        },
        {
          icon: "💰",
          title: "Defi去中心化金融",
          description:
            "Defi（Decentralized Finance)去中心化金融是建立在区块链技术之上，旨在消除传统金融中的中心化机构和中介，实现金融服务的民主化和信任化。Defi 具有以下几个主要特点和优势：1. 去除中心化控制：传统金融体系中，银行等中心化机构对金融服务拥有较大的控制权。而在 Defi 中，通过区块链的分布式账本技术和智能合约，实现金融交易和服务的去中心化处理，降低了中心化机构的风险和单点故障。2. 开放性和无许可：任何人只要有互联网连接和相关的加密资产，就可以参与 Defi 应用和服务，无需经过中心化机构的审批和许可。3. 透明性：所有的交易和操作都记录在区块链上，公开透明且可追溯，减少了信息不对称和潜在的欺诈行为。4. 可编程性和自动化：利用智能合约，可以实现金融服务的自动化执行和复杂的业务逻辑。 ",
          image: require("@/assets/img/bg/defi.webp"),
        },
        {
          icon: "⚖️",
          title: "DAO去中心化自治组织",
          description:
            "去中心化自治组织（DAO）的发展：DAO 是现代组织架构与去中心化的结合，成员通过持有组织的代币获得提案权和投票权，决策取得共识后由智能合约自动执行。 ",
          image: require("@/assets/img/bg/dao.jpg"),
        },
        {
          icon: "🌟",
          title: "项目知名度及公信力",
          description:
            "爱尚购自2022.10.15上线以来，举办两届中国数字经济发展论坛，邀请到多位专家学者与相关领导人出席，如武汉吴余龙博士，中国商务部原副部长张志刚先生，中国科技部原副部长吴忠泽先生等亲临现场。举办两次企业年度盛会，与联盟商家和会员欢聚一堂共襄盛典。爱尚购历经波折依然砥砺前行，进行技术与服务的迭代升级，保障平台的安全性与可持续性发展。现在爱尚购商城3.0版启动上线，把之前所有会员的数据权益与数字资产平移，不让任何一个会员的财产受到损失，共享企业发展红利，获得所有商家、会员的信赖与业界良好的口碑。",
          image: require("@/assets/img/bg/xm.jpg"),
        },
      ],
    };
  },
  mounted() {
    this.startImageCarousel();
  },
  methods: {
    selectItem(index) {
      this.selected = index;
      this.$nextTick(() => {
        document.getElementById("content-focus").scrollIntoView({
          behavior: "smooth",
        });
      });
    },
    startImageCarousel() {
      setInterval(() => {
        this.currentImageIndex =
          (this.currentImageIndex + 1) % this.carouselItems.length;
      }, 5000);
    },
    expandImage(image) {
      this.expandedImage = image;
    },
  },
};
</script>

<style scoped>
@import "@/assets/styles/common.css";
</style>

<template>
  <nav
    class="navbar navbar-expand-lg navbar-dark fixed-top"
    :class="{ 'navbar-hidden': navbarHidden }"
  >
    <div class="container-fluid" style="height: 70px">
      <router-link to="/">
        <div style="display: flex">
          <div v-show="showLogo" class="logo-image"></div>
          <AccCanvas v-show="!showLogo" />
        </div>
      </router-link>

      <div
        id="nav-icon2"
        :class="{ open: navbarExpanded }"
        class="navbar-toggler"
        @click="toggleNavbar"
      >
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="collapse navbar-collapse" id="navbarNav" ref="navbarNav">
        <ul class="navbar-nav ms-auto">
          <li class="nav-item">
            <router-link class="nav-link" to="/" @click="toggleNavbar"
              >首页</router-link
            >
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/company" @click="toggleNavbar"
              >企业介绍</router-link
            >
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/analysis" @click="toggleNavbar"
              >行业分析</router-link
            >
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/services" @click="toggleNavbar"
              >服务对象</router-link
            >
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/Supply" @click="toggleNavbar"
              >供应链介绍</router-link
            >
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/Advantage" @click="toggleNavbar"
              >核心优势</router-link
            >
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/Alliance" @click="toggleNavbar"
              >联盟商家</router-link
            >
          </li>
          <!-- <li class="nav-item">
            <router-link class="nav-link" to="/GameView" @click="toggleNavbar"
              >小游戏</router-link
            >
          </li> -->
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import AccCanvas from "@/components/acc.vue";

export default {
  name: "AsgNavbar",
  components: {
    AccCanvas,
  },
  data() {
    return {
      navbarHidden: false,
      navbarExpanded: false,
      lastScrollTop: 0,
      isMobile: false,
      showLogo: true,
    };
  },
  mounted() {
    this.checkMobile();
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.checkMobile);
    setInterval(() => {
      this.showLogo = !this.showLogo;
    }, 3000);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.checkMobile);
  },
  methods: {
    handleScroll() {
      const currentScrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      // 检查是否需要关闭导航栏
      if (this.navbarExpanded) {
        this.navbarExpanded = false;
        this.$refs.navbarNav.classList.remove("show");
      }

      // 检查是否需要隐藏导航栏
      if (currentScrollTop > this.lastScrollTop && currentScrollTop > 50) {
        this.navbarHidden = true;
      } else {
        this.navbarHidden = false;
      }

      this.lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop;
    },
    toggleNavbar() {
      this.navbarExpanded = !this.navbarExpanded;
      if (this.navbarExpanded) {
        this.$refs.navbarNav.classList.add("show");
      } else {
        this.$refs.navbarNav.classList.remove("show");
      }
    },
    checkMobile() {
      this.isMobile = window.innerWidth <= 991;
    },
  },
};
</script>

<style scoped>
#nav-icon2 {
  width: 50px;
  height: 50px;
  position: relative;
  cursor: pointer;
  transition: 0.5s;
}

#nav-icon2 span {
  display: block;
  position: absolute;
  height: 9px;
  width: 50%;
  background: #d3531a;
  opacity: 1;
  transition: 0.25s ease-in-out;
}

#nav-icon2 span:nth-child(even) {
  left: 50%;
  border-radius: 0 9px 9px 0;
}

#nav-icon2 span:nth-child(odd) {
  left: 0;
  border-radius: 9px 0 0 9px;
}

#nav-icon2 span:nth-child(1),
#nav-icon2 span:nth-child(2) {
  top: 0;
}

#nav-icon2 span:nth-child(3),
#nav-icon2 span:nth-child(4) {
  top: 18px;
}

#nav-icon2 span:nth-child(5),
#nav-icon2 span:nth-child(6) {
  top: 36px;
}

#nav-icon2.open span:nth-child(1),
#nav-icon2.open span:nth-child(6) {
  transform: rotate(45deg);
}

#nav-icon2.open span:nth-child(2),
#nav-icon2.open span:nth-child(5) {
  transform: rotate(-45deg);
}

#nav-icon2.open span:nth-child(1) {
  left: 5px;
  top: 7px;
}

#nav-icon2.open span:nth-child(2) {
  left: calc(50% - 5px);
  top: 7px;
}

#nav-icon2.open span:nth-child(3) {
  left: -50%;
  opacity: 0;
}

#nav-icon2.open span:nth-child(4) {
  left: 100%;
  opacity: 0;
}

#nav-icon2.open span:nth-child(5) {
  left: 5px;
  top: 29px;
}

#nav-icon2.open span:nth-child(6) {
  left: calc(50% - 5px);
  top: 29px;
}
.navbar {
  font-weight: 700;
  transition: transform 0.3s ease, opacity 0.3s ease;
  height: 80px;
  z-index: 10001;
  background: linear-gradient(
    to bottom,
    #7fce41 10%,
    #a0e571 70%,
    #7fce41 100%
  );
  display: flex;
  align-items: center;
}

.logo-image {
  width: 70px;
  height: 70px;
  background-image: url("@/assets/img/home/logo.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
}

.crypto-animation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 30px;
  overflow: hidden;
  z-index: 1001;
}

.crypto-coin {
  width: 30px;
  height: 30px;
  background-color: gold; /* 使用金色代表虚拟币 */
  border-radius: 50%;
  position: absolute;
  left: -50px; /* 初始位置在左边屏幕外 */
  top: 10px;
  animation: moveCoin 5s linear infinite; /* 控制虚拟币的动画 */
  box-shadow: 0 0 10px rgba(255, 215, 0, 0.7); /* 添加发光效果 */
}

/* 虚拟币从左到右的动画 */
@keyframes moveCoin {
  0% {
    left: -50px;
  }
  100% {
    left: calc(100% + 50px); /* 最终位置在右边屏幕外 */
  }
}

@media (max-width: 991px) {
  .crypto-animation {
    display: block;
  }
}

@media (min-width: 992px) {
  .crypto-animation {
    display: none;
  }
}

.navbar-toggler {
  border-color: transparent; /* 移除默认的边框颜色 */
}

.navbar-toggler:focus {
  outline: none; /* 移除聚焦时的边框 */
}

.navbar-nav .nav-link {
  font-size: 1.2rem; /* 字体大小调整 */
  color: black; /* 亮灰色字体 */
}

.navbar-nav .nav-link:hover,
.navbar-nav .router-link-exact-active {
  color: #ffa500; /* 悬停和激活状态下为橙色 */
  background-color: transparent; /* 无背景色 */
}
@media (min-width: 992px) {
  .navbar-nav .nav-link {
    margin-right: 2rem;
  }
}
@media (max-width: 991.98px) {
  .navbar-collapse {
    transition: max-height 0.3s ease; /* 导航栏展开和收起过渡效果 */
    overflow: hidden; /* 确保动画效果正常 */
    max-height: 0; /* 默认收起状态 */
    position: absolute; /* 绝对定位，以便覆盖其他内容 */
    top: 80px; /* 与导航栏底部对齐 */
    left: 0;
    right: 0;
    background: #7fce41 10%;
  }
  .navbar-collapse.show {
    max-height: 500px; /* 展开时的最大高度 */
  }
}

.navbar-hidden {
  transform: translateY(-100%);
  opacity: 0;
}
</style>

<template>
  <div>
    <!-- Loader -->
    <div class="loader-wrap">
      <svg viewBox="0 0 1000 1000" preserveAspectRatio="none">
        <path id="svg" d="M0,1005S175,995,500,995s500,5,500,5V0H0Z"></path>
      </svg>

      <div class="loader-wrap-heading">
        <div class="load-text">
          <span>爱</span>
          <span>尚</span>
          <span>购</span>
        </div>
      </div>
    </div>

    <!-- Main Content -->
    <ContentList />
  </div>
</template>

<script>
import { onMounted } from 'vue';
import { gsap } from 'gsap';
import ContentList from '@/components/ContentList.vue';

export default {
  name: "AsgHome",
  components: {
    ContentList
  },
  setup() {
    onMounted(() => {
      const svg = document.getElementById("svg");
      const tl = gsap.timeline();
      const curve = "M0 502S175 272 500 272s500 230 500 230V0H0Z";
      const flat = "M0 2S175 1 500 1s500 1 500 1V0H0Z";

      tl.to(".loader-wrap-heading .load-text", {
        delay: 1,
        y: -100,
        opacity: 0,
      });
      tl.to(svg, {
        duration: 0.5,
        attr: { d: curve },
        ease: "power2.easeIn",
      }).to(svg, {
        duration: 0.5,
        attr: { d: flat },
        ease: "power2.easeOut",
      });
      tl.to(".loader-wrap", {
        y: -1500,
      });
      tl.to(".loader-wrap", {
        zIndex: -1,
        display: "none",
      });
      tl.from(
        "header",
        {
          y: 200,
        },
        "-=1.5"
      );
      tl.from(
        "header .container",
        {
          y: 40,
          opacity: 0,
          delay: 0.2,
        },
        "-=1.5"
      );
    });
  }
};
</script>

<style scoped>
body.loaded {
  overflow: hidden !important;
  height: 100% !important;
}

.loader-wrap {
  position: fixed;
  z-index: 10;
  height: 100vh;
  width: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  z-index: 99999999999999;
}

.loader-wrap svg {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 110vh;
  fill: #000;
}

.loader-wrap .loader-wrap-heading .load-text {
  font-size: 20px;
  font-weight: 200;
  letter-spacing: 15px;
  text-transform: uppercase;
  z-index: 20;
  color: #fff;
}

.load-text span {
  animation: loading 1s infinite alternate;
  font-size:4rem;
}

.load-text span:nth-child(1) { animation-delay: 0s; }
.load-text span:nth-child(2) { animation-delay: 0.1s; }
.load-text span:nth-child(3) { animation-delay: 0.2s; }
.load-text span:nth-child(4) { animation-delay: 0.3s; }
.load-text span:nth-child(5) { animation-delay: 0.4s; }
.load-text span:nth-child(6) { animation-delay: 0.5s; }
.load-text span:nth-child(7) { animation-delay: 0.6s; }

@keyframes loading {
  0% { opacity: 1; }
  100% { opacity: 0; }
}
</style>
